import React from 'react'
import { ShellProps } from './types/shell'
import RoutePoliciesTab from 'components/policies/routePoliciesTab'
import PoliciesProvider from 'context/policies/PoliciesProvider'
import SolutionsProvider from 'context/solutions/SolutionsProvider'
import CollectionsProvider from 'context/devices/CollectionsProvider'
import GrantsProvider from 'context/grants/GrantsProvider'
import ConfigProvider from 'context/config/ConfigProvider'
import projectNames from './configs/projectNames'
import { Stack } from '@jarvis/web-stratus-client'
import { ThemeProvider, ToastProvider, useToast } from '@veneer/core'
import SimplePolicyPreview from 'components/policies/preview/SimplePreview'
import DeviceComplianceInfo from 'components/policies/deviceComplianceInfo'
import AppConfigModal from 'components/policies/appConfig'
import { fixTheme } from 'common/utilities'

declare global {
  interface Window {
    Shell: ShellProps
  }
}
export default function Root(props) {
  const { v1 } = window.Shell as ShellProps
  const localization = {
    language: v1?.localization?.language || 'en',
    country: v1?.localization?.country || 'US'
  }
  const navigation = v1?.navigation
  const accessControl = v1?.accessControl
  const stack = v1?.app?.getAuthStack()
  const events = v1?.events
  const featureFlags = v1?.featureFlags
  const tenantId = v1?.orgSelector?.getOrgTenantId()
  const themeProviderProps = v1?.theme?.getThemeProviderProperties?.()
  const mfeProps = {
    events,
    navigation,
    accessControl,
    featureFlags
  }
  // Custom properties
  const {
    policyId,
    deviceId,
    deviceModelNumber,
    entitlements,
    appConfig, // { appId, mocId, header, preview, config, onClose, onChange },
    ...otherProps
  } = props
  if (!otherProps.useToast) {
    otherProps.useToast = useToast
  }

  const arrakisStack = (url) =>
    'https://stratus-' +
    (stack === Stack.prod ? 'pro' : stack === Stack.stage ? 'stg' : 'pie') +
    '.tropos-rnd.com/' +
    url

  const WithToast = (content) =>
    props.useToast ? (
      content.children
    ) : (
      <ToastProvider>{content.children}</ToastProvider>
    )

  return (
    <section id={projectNames.packageJsonName}>
      <ThemeProvider {...fixTheme(themeProviderProps)}>
        <ConfigProvider {...localization}>
          <PoliciesProvider stack={stack} authProvider={v1.authProvider}>
            <CollectionsProvider stack={stack} authProvider={v1.authProvider}>
              <GrantsProvider stack={stack} authProvider={v1.authProvider}>
                <SolutionsProvider
                  portfolioStack={arrakisStack('portfoliomgt-api/v1')}
                  contentBrokerStack={arrakisStack('content-broker-api/v1')}
                  authProvider={v1.authProvider}
                  tenantId={tenantId}
                >
                  {policyId ? (
                    <SimplePolicyPreview policyId={policyId} />
                  ) : deviceId ? (
                    <WithToast>
                      <DeviceComplianceInfo
                        deviceId={deviceId}
                        entitlements={entitlements}
                        deviceModelNumber={deviceModelNumber}
                        useToast={otherProps.useToast}
                        containerId={otherProps.containerId}
                      />
                    </WithToast>
                  ) : appConfig?.appId && appConfig?.mocId ? (
                    <AppConfigModal {...appConfig} />
                  ) : (
                    <WithToast>
                      <RoutePoliciesTab {...otherProps} {...mfeProps} />
                    </WithToast>
                  )}
                </SolutionsProvider>
              </GrantsProvider>
            </CollectionsProvider>
          </PoliciesProvider>
        </ConfigProvider>
      </ThemeProvider>
    </section>
  )
}
