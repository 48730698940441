import React from 'react'
import { IconInfo, Tooltip } from '@veneer/core'
import 'styles/global.scss'

const HelpButton = (props) => {
  const icon = (
    <IconInfo className={props.className} color={'gray7'} size={16} />
  )
  return (
    <>
      {props.enabled ? (
        <Tooltip
          arrow
          placement={props.placement || 'trailing'}
          content={props.description}
          portal={!!props.portal}
        >
          {icon}
        </Tooltip>
      ) : (
        <div className={'lineHeight0'}>{icon}</div>
      )}
    </>
  )
}

export default HelpButton
