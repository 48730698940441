import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  Button,
  ButtonGroup,
  ColumnIndexTypes,
  CustomImage,
  Modal,
  Select,
  Table
} from '@veneer/core'
import { SortTypes } from '@veneer/core/dist/scripts/table'
import configContext from 'context/config/configContext'
import solutionsContext from 'context/solutions/solutionsContext'
import policiesContext from 'context/policies/policiesContext'
import 'components/policies/modal/index.scss'
import 'styles/global.scss'
import { FlexRow, ModalTableHeight } from 'styles/styles'
import { abcSort, getRowSelectAllState } from 'common/utilities'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'

export enum AppDeploymentEnum {
  UUID = 'uuid',
  APP_ID = 'appId',
  NAME = 'name',
  ICON = 'icon',
  VERSION = 'version',
  PROVIDER = 'provider',
  MOC_ID = 'mocId'
  // CONFIG_ID = 'configId' // NO_APP_CONFIG
}

const copyAppVersion = (app, version) => {
  app[AppDeploymentEnum.APP_ID] = version[AppDeploymentEnum.APP_ID]
  app[AppDeploymentEnum.NAME] = version[AppDeploymentEnum.NAME]
  app[AppDeploymentEnum.PROVIDER] = version[AppDeploymentEnum.PROVIDER]
  app[AppDeploymentEnum.MOC_ID] = version[AppDeploymentEnum.MOC_ID]
  app[AppDeploymentEnum.VERSION] = version[AppDeploymentEnum.VERSION]
}

const orderDescending = 'descending' as SortTypes

const SelectAppModal = (props) => {
  const { value, onClose, onChange, localizationPath } = props
  const { tt } = useContext(configContext)
  const { showError } = useContext(policiesContext)
  const { solutions, getSolutions } = useContext(solutionsContext)
  const [tableData, setTableData] = useState([])
  const [orderType, setOrderType] = useState(orderDescending)
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)

  useEffect(() => {
    getSolutions((error) => showError(error))
  }, [])

  useEffect(
    () =>
      setTableData(
        solutions
          ? abcSort(
              solutions.map((x) => {
                const item = { ...x }
                const found = value.find(
                  (y) =>
                    y[AppDeploymentEnum.UUID] === item[AppDeploymentEnum.UUID]
                )
                if (found) {
                  copyAppVersion(item, found)
                  // item[AppDeploymentEnum.CONFIG_ID] = found[AppDeploymentEnum.CONFIG_ID] // NO_APP_CONFIG
                }
                return tableItem(item, !!found)
              }),
              (x) => x.rowConfig.item[AppDeploymentEnum.NAME],
              orderType
            )
          : null
      ),
    [solutions, orderType]
  )

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    setTableData(
      tableData.map((x) => {
        x.rowConfig.selected = checked
        return {
          ...x,
          [AppDeploymentEnum.VERSION]: versionControl(x.rowConfig)
        }
      })
    )
  }

  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const found = tableData.find(({ id }) => id === rowId)
    if (found) {
      found.rowConfig.selected = checked
      setTableData(
        tableData.map((x) =>
          x == found
            ? { ...x, [AppDeploymentEnum.VERSION]: versionControl(x.rowConfig) }
            : x
        )
      )
    }
  }

  const handleVersionSelect = (v, rowConfig) => {
    const selectedVersion = rowConfig.item.versions.find(
      ({ version }) => version === v
    )
    if (selectedVersion) {
      const item = { ...rowConfig.item }
      copyAppVersion(item, selectedVersion)
      setTableData((prev) =>
        prev.map((x) =>
          x.rowConfig == rowConfig
            ? tableItem(item, rowConfig.selected, true)
            : x
        )
      )
    }
  }

  useEffect(() => {
    setRowSelectAllState(
      getRowSelectAllState(
        tableData?.filter((x) => x.rowConfig.selected).length,
        tableData?.length
      )
    )
  }, [tableData])

  const getData = () => {
    return tableData
      ? tableData
          .filter((v) => v.rowConfig.selected)
          .map(({ rowConfig: { item } }) => {
            const app = { ...item }
            delete app.versions
            return app
          })
      : []
  }

  const compareData = (a, b) =>
    a.length === b.length &&
    a.every((x) => {
      const found = b.find(
        (y) => y[AppDeploymentEnum.UUID] === x[AppDeploymentEnum.UUID]
      )
      return (
        found &&
        found[AppDeploymentEnum.VERSION] === x[AppDeploymentEnum.VERSION]
        // && !found[AppDeploymentEnum.CONFIG_ID] == !x[AppDeploymentEnum.CONFIG_ID] // NO_APP_CONFIG
      )
    })

  const enableSave = useMemo(
    () => !compareData(getData(), value),
    [tableData, value]
  )

  const tableColumns = useMemo(() => {
    const id = 'id'
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id, label: id, index },
      {
        id: AppDeploymentEnum.NAME,
        label: getLocalized('app-name'),
        sortable: true
      },
      {
        id: AppDeploymentEnum.PROVIDER,
        label: getLocalized('app-provider'),
        sortable: false
      },
      {
        id: AppDeploymentEnum.VERSION,
        label: getLocalized('app-version'),
        sortable: false
      }
    ]
  }, [])

  const handleSave = () => {
    if (
      tableData.some(
        ({ rowConfig }) =>
          rowConfig.selected && !rowConfig.item[AppDeploymentEnum.VERSION]
      )
    ) {
      setTableData((prev) => {
        return prev.map((row) => {
          row.rowConfig.versionError = true
          row[AppDeploymentEnum.VERSION] = versionControl(row.rowConfig)
          return row
        })
      })
      return
    }
    onChange(getData())
    onClose()
  }

  const versionControl = (rowConfig) => {
    const displayError =
      rowConfig.selected &&
      !rowConfig.item[AppDeploymentEnum.VERSION] &&
      rowConfig.versionError
    return (
      <Select
        options={
          rowConfig.item.versions
            ? rowConfig.item.versions.map(({ version }) => ({
                label: version,
                value: version
              }))
            : []
        }
        helperText={
          displayError ? getLocalized('common.errors.not-selected') : null
        }
        error={displayError}
        disabled={!rowConfig.selected}
        clearIcon={false}
        id={'select-app'}
        placeholder={getLocalized('common.select-option')}
        value={[rowConfig.item[AppDeploymentEnum.VERSION] || '']}
        onChange={({ value: v }) => handleVersionSelect(v, rowConfig)}
      />
    )
  }

  const tableItem = (item, selected, versionError = false) => {
    const rowConfig = { item, selected, versionError }
    return {
      id: item[AppDeploymentEnum.UUID],
      [AppDeploymentEnum.NAME]: (
        <FlexRow className={'alignCenter'}>
          <CustomImage size={36} src={item[AppDeploymentEnum.ICON]} />
          <span className={'marginLeft4'}>{item[AppDeploymentEnum.NAME]}</span>
        </FlexRow>
      ),
      [AppDeploymentEnum.PROVIDER]: item[AppDeploymentEnum.PROVIDER],
      [AppDeploymentEnum.VERSION]: versionControl(rowConfig),
      rowConfig
    }
  }

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized('select-app')}
      className={'extra-large-policy-modal'}
      data-testid={'id-select-app-modal'}
      footer={
        <ButtonGroup>
          <Button disabled={!enableSave} onClick={handleSave}>
            {getLocalized('common.save')}
          </Button>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <p className={'paddingBottom16'}>{getLocalized('select-app-message')}</p>
      <ModalTableHeight>
        <Table
          columns={tableColumns}
          data={tableData || []}
          loading={!tableData}
          onSort={(_, { type }) => setOrderType(type)}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          rowSelector={'multiSelection'}
          rowSelectAllState={rowSelectAllState}
          className={'paddingSecondCol4 paddingFourthCol4'}
          data-testid={'id-select-app-modal-table'}
          preferences={{
            sortBy: { id: AppDeploymentEnum.NAME, type: orderType },
            width: [
              { columnId: AppDeploymentEnum.NAME, width: 240 },
              { columnId: AppDeploymentEnum.VERSION, width: 180 },
              { columnId: AppDeploymentEnum.PROVIDER, width: 160 }
            ]
          }}
          i18n={Retrievei18nItems()}
        />
      </ModalTableHeight>
    </Modal>
  )
}

export default SelectAppModal
