import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Item } from '../PoliciesConfiguration'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

export default class WakeOnItems {
  static events: Item[] = [
    {
      value: `${PolicyAttributeEnum.Sleep_Settings}.on-events.all`,
      label: 'on-events-all',
      option: 'all'
    },
    {
      value: `${PolicyAttributeEnum.Sleep_Settings}.on-events.nw-port`,
      label: 'on-events-nw-port',
      option: 'networkPortActivity'
    },
    {
      value: `${PolicyAttributeEnum.Sleep_Settings}.on-events.power-btn`,
      label: 'on-events-power-btn',
      option: 'powerButtonPress'
    }
  ]

  static wakeOnEvents = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? WakeOnItems.events
      : constraints.filterSettingOptions(
          WakeOnItems.events,
          ConstraintsResourceEnum.sleepCfg,
          ConstraintsSettingEnum.autoOnEvents
        )
}
