import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Table, Button } from '@veneer/core'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { ObjectAttributeDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import FaxReceiveItems from 'context/policies/dropboxItems/FaxReceiveItems'
import FaxMethodItems from 'context/policies/dropboxItems/FaxMethodItems'
import PrintEmailNotificationItems from 'context/policies/dropboxItems/PrintEmailNotificationItems'
import MediaTrayItems from 'context/policies/dropboxItems/MediaTrayItems'
import FaxReceiveCommonModal, {
  faxReceiveCommonEnum
} from './FaxReceiveCommonModal'
import FaxReceiveInternalModemModal, {
  faxReceiveModemEnum
} from './FaxReceiveInternalModemModal'
import WarningMessage from '../WarningMessage'
import HelpButton from '../../../HelpButton'
import { FlexColumn, PreviewLabel } from 'styles/styles'
import 'styles/global.scss'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import LabelRadioButtons from 'common/controls/labelRadioButtons'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

const columnId = 'id'
const columnOption = 'option'
const columnValue = 'value'
const tablePref = {
  width: [
    { columnId: columnOption, width: 240 },
    { columnId: columnValue, width: 360 }
  ]
}

const filler = (id) => {
  return { id, title: `${id}-title`, button: `${id}-settings` }
}
const tables = [filler('common'), filler('modem')] // FaxReceiveItems

const FaxReceiveControl = (props) => {
  const description: ObjectAttributeDescription = props.description
  const {
    attributes,
    localizationPath,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)

  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    addError,
    removeError,
    displayAllErrors
  } = useContext(settingsContext)

  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const initArray = (x) => Array.from(Array(tables.length)).map(() => x)
  const [error, setError] = useState(initArray(false))
  const [showModal, setShowModal] = useState(initArray(false))
  const [displayError, setDisplayError] = useState(initArray(displayAllErrors))
  const [tableData, setTableData] = useState(initArray([]))

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const faxReceive = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(faxReceive) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  useEffect(
    () => setDisplayError((x) => x.map((y) => y || displayAllErrors)),
    [displayAllErrors]
  )

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  const onChange = (v, i?) => {
    if (i !== undefined) {
      setDisplayError((x) => {
        x[i] = true
        return x
      })
    }
    const newValue = { ...value, ...v }
    setTableData(getTableData(newValue))
    setValue(newValue)
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const compliance = useMemo(
    () => PoliciesHelper.getCompliance(description, deviceSettings),
    [deviceSettings]
  )

  const getTableData = (val) => {
    const item = (id, items?) => {
      const status = compliance[id]
      const found = items?.find((v) => v.value === val[id])
      const text = found ? getLocalized(found.label) : val[id]
      return {
        id,
        value: (
          <>
            {text}
            {status && <PreviewItem compliance={status} />}
          </>
        ),
        option: getLocalized(id)
      }
    }
    return [
      [
        item(faxReceiveCommonEnum.TRAY, MediaTrayItems.faxReceiveTrays),
        item(
          faxReceiveCommonEnum.NOTIFICATION_CONDITION,
          PrintEmailNotificationItems.emailNotificationConditions
        ),
        item(
          faxReceiveCommonEnum.NOTIFICATION_METHOD,
          PrintEmailNotificationItems.emailNotificationMethods
        ),
        item(faxReceiveCommonEnum.NOTIFICATION_ADDRESS)
      ],
      [
        item(faxReceiveModemEnum.RINGS_TO_ANSWER),
        item(
          faxReceiveModemEnum.RINGER_VOLUME,
          FaxReceiveItems.faxModemRingerVolume
        )
      ]
    ]
  }

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: columnId, label: columnId, index },
      { id: columnOption, label: getLocalized('option-name') },
      { id: columnValue, label: getLocalized('value') }
    ]
  }, [])

  useEffect(() => setTableData(getTableData(value)), [value])

  const i18nItems = Retrievei18nItems()
  const enabled = isEnabled(attribute)
  const table = (i, cn?) => (
    <FlexColumn key={tables[i].button} className={cn}>
      {onAttributeChange ? (
        <div className={'marginBottom12'}>
          <Button
            onClick={() => setShowModal(showModal.map((_, j) => j === i))}
            appearance={'secondary'}
            disabled={!enabled}
          >
            {getLocalized(tables[i].button)}
          </Button>
        </div>
      ) : (
        <PreviewLabel>{getLocalized(tables[i].title)}</PreviewLabel>
      )}
      <Table
        data={tableData[i]}
        className={'widthColAuto'}
        columns={tableColumns}
        preferences={tablePref}
        i18n={i18nItems}
      />
      {error[i] && (
        <WarningMessage
          type={MessageTypesEnum.ERROR}
          message={getLocalized('common.errors.not-selected')}
          id={`${attribute}.${tables[i].title}.error`}
        />
      )}
    </FlexColumn>
  )

  const index = FaxMethodItems.faxReceiveMethods().findIndex(
    (x) => x.value === value.method
  )

  useEffect(() => {
    const ids = [
      [faxReceiveCommonEnum.TRAY, faxReceiveCommonEnum.NOTIFICATION_CONDITION],
      [faxReceiveModemEnum.RINGS_TO_ANSWER, faxReceiveModemEnum.RINGER_VOLUME]
    ]
    const err = Array.from(Array(tables.length)).map(
      enabled
        ? (_, i) => (!i || i === index + 1) && ids[i].some((y) => !value[y])
        : () => false
    )
    if (!err[0]) {
      // check dependant fields
      err[0] =
        value[faxReceiveCommonEnum.NOTIFICATION_CONDITION] !== //notify
          PrintEmailNotificationItems.emailNotificationConditions[0].value &&
        value[faxReceiveCommonEnum.NOTIFICATION_METHOD] === // by email
          PrintEmailNotificationItems.emailNotificationMethods[1].value &&
        !value[faxReceiveCommonEnum.NOTIFICATION_ADDRESS]
    }
    setError(err.map((x, i) => x && displayError[i]))
    err.forEach((x, i) =>
      x
        ? addError(props.id, attribute + tables[i].button, displayError[i])
        : removeError(props.id, attribute + tables[i].button)
    )
  }, [enabled, value, displayError])

  const modalParams = (index) => {
    return {
      value,
      getLocalized,
      onChange: (v) => onChange(v, index),
      onClose: () => setShowModal(showModal.map(() => false)),
      id: tables[index].id,
      key: tables[index].id
    }
  }

  const modals = [
    (modalProps) => <FaxReceiveCommonModal {...modalProps} />,
    (modalProps) => <FaxReceiveInternalModemModal {...modalProps} />
  ]

  const radio = (label, methods) => {
    return onAttributeChange ? (
      <LabelRadioButtons
        disabled={!enabled}
        label={getLocalized(label)}
        className={'marginBottom16'}
        onChange={(_, method) => onChange({ method })}
        id={'id-fax-receive-method'}
        value={value.method}
        helpButton={
          <HelpButton
            enabled={enabled}
            description={getLocalized(label + '_help')}
          />
        }
        options={methods.map((item) => {
          return { value: item.value, label: getLocalized(item.label) }
        })}
      />
    ) : (
      index >= 0 && (
        <PreviewItem
          compliance={compliance.method}
          className={'marginBottom16'}
          label={`${localizationPath}.${label}`}
          value={getLocalized(methods[index].label)}
        />
      )
    )
  }

  return (
    <>
      <FlexColumn>
        {radio(
          'method',
          FaxMethodItems.faxReceiveMethods(description.constraints)
        )}
        {table(0)}
        {index >= 0 &&
          index < tables.length - 1 &&
          table(index + 1, 'paddingTop16')}
      </FlexColumn>
      {showModal.map((x, i) => x && modals[i](modalParams(i)))}
    </>
  )
}

export default memo(FaxReceiveControl)
