import React, { useContext, useState, useEffect } from 'react'
import { Button, ButtonGroup, IconWarningAlt, Modal } from '@veneer/core'
import configContext from 'context/config/configContext'
import policiesContext from 'context/policies/policiesContext'
import { FlexRow } from 'styles/styles'
import { equalPolicies } from 'common/policyAttributeComparator'

const WarningSave = (props) => {
  const cContext = useContext(configContext)
  const { t } = cContext
  const getLocalized = (key: string): string => {
    return t(`policy.details.unsavedModal.${key}`)
  }

  const policyContext = useContext(policiesContext)
  const { selectedPolicy, clonedPolicy } = policyContext

  const [blockedUrl, setBlockedUrl] = useState(null)
  const { history } = props

  useEffect(() => {
    return history.block((ev) => {
      if (blockedUrl) {
        setBlockedUrl(null)
      } else if (!equalPolicies(selectedPolicy, clonedPolicy)) {
        setBlockedUrl(ev.pathname)
        return false
      }
      return true
    })
  }, [history, blockedUrl, selectedPolicy, clonedPolicy])

  const handleCancelUnsavedChangesModal = () => {
    setBlockedUrl(null)
  }

  const handleConfirmUnsavedChangesModal = () => {
    history.push(blockedUrl)
  }

  return (
    !!blockedUrl && (
      <Modal
        className={'small-policy-modal'}
        data-testid={'id-warning-save-modal'}
        onClose={handleCancelUnsavedChangesModal}
        closeOnBlur={false}
        show={!!blockedUrl}
        footer={
          <ButtonGroup>
            <Button
              id={'unsaved-changes-confirm-button'}
              onClick={handleConfirmUnsavedChangesModal}
            >
              {getLocalized('leave')}
            </Button>
            <Button
              id={'unsaved-changes-cancel-button'}
              appearance={'secondary'}
              onClick={handleCancelUnsavedChangesModal}
            >
              {t('common.cancel')}
            </Button>
          </ButtonGroup>
        }
      >
        <FlexRow className={'marginBottom20 alignCenter'}>
          <IconWarningAlt size={36} filled color={'darkOrange5'} />
          <h4
            className={'marginLeft8'}
            data-testid={'id-warning-save-modal-title'}
          >
            {getLocalized('title')}
          </h4>
        </FlexRow>
        <p
          className={'paddingBottom16 lineHeight20'}
          data-testid={'id-warning-save-modal-text-1'}
        >
          {getLocalized('confirmation')}
        </p>
        <p data-testid={'id-warning-save-modal-text-2'}>
          {getLocalized('warning')}
        </p>
      </Modal>
    )
  )
}

export default WarningSave
