import React, { useContext, useEffect, useState, useMemo, memo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Button, Table } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { ObjectAttributeDescription } from 'context/policies/PoliciesConfiguration'
import WarningMessage from '../WarningMessage'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import DelayVeryLowMessageModal, {
  DelayVeryLowMessageEnum
} from 'components/policies/settings/attributes/device/controls/DelayVeryLowMessage/DelayVeryLowMessageModal'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import { FlexRow, FlexColumn, TextBlack } from 'styles/styles'
import 'styles/global.scss'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

enum ColumnEnum {
  ID = 'id',
  THRESHOLD = 'threshold',
  VALUE = 'value'
}

const tablePreferences = {
  width: [
    { columnId: ColumnEnum.THRESHOLD, width: 360 },
    { columnId: ColumnEnum.VALUE, width: 120 }
  ]
}

const DelayVeryLowMessageControl = (props) => {
  const description: ObjectAttributeDescription = props.description
  const {
    id,
    attributes,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description
  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    addError,
    removeError,
    displayAllErrors
  } = useContext(settingsContext)

  const { tt } = useContext(configContext)
  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const [tableData, setTableData] = useState([])

  const compliance = useMemo(
    () => PoliciesHelper.getCompliance(description, deviceSettings),
    [deviceSettings]
  )

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  const getTableData = (val) => {
    const thresholds = []
    Object.values(DelayVeryLowMessageEnum).forEach((x) => {
      if (val[x] !== undefined) {
        thresholds.push({
          id: x,
          [ColumnEnum.THRESHOLD]: getLocalized(x),
          [ColumnEnum.VALUE]: (
            <>
              {val[x]}
              {compliance[x] && <PreviewItem compliance={compliance[x]} />}
            </>
          ),
          rowConfig: { value: { threshold: x, value: val[x] } }
        })
      }
    })
    return thresholds
  }

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const msgs = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(msgs) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const showError = displayError || displayAllErrors
  const enabled = isEnabled(attribute)
  const emptyTable = !tableData.length
  useEffect(() => {
    const err = enabled && emptyTable
    setError(showError && err)
    err ? addError(id, attribute, showError) : removeError(id, attribute)
  }, [enabled, emptyTable, showError])

  useEffect(() => setTableData(getTableData(value)), [value])

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: ColumnEnum.ID, label: ColumnEnum.ID, index },
      {
        id: ColumnEnum.THRESHOLD,
        label: getLocalized('thresholds-label-column')
      },
      { id: ColumnEnum.VALUE, label: getLocalized('thresholds-value-column') }
    ]
  }, [])

  const setData = (arrayThresholds) => {
    setDisplayError(true)
    const newValue = {}
    arrayThresholds.forEach((x) => {
      const { threshold, value } = x
      if (threshold && value !== undefined) {
        newValue[threshold] = value
      }
    })
    setTableData(getTableData(newValue))
    setValue(newValue)
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  return (
    <>
      <FlexColumn>
        {onAttributeChange && (
          <FlexRow className={'marginBottom12 alignCenter'}>
            <Button
              appearance="secondary"
              onClick={() => setShowModal(true)}
              disabled={!enabled}
            >
              {getLocalized(
                tableData.length
                  ? 'thresholds-change-button'
                  : 'thresholds-select-button'
              )}
            </Button>
            <TextBlack className={'marginLeft12'} disabled={!enabled}>
              {getLocalized('thresholds-selected', {
                selectedMessagesCount: tableData.length
              })}
            </TextBlack>
          </FlexRow>
        )}
        <Table
          columns={tableColumns}
          data={tableData}
          className={'widthColAuto'}
          data-testid={'id-delay-very-low-msg-table'}
          preferences={tablePreferences}
          i18n={Retrievei18nItems()}
        />
        {error && (
          <WarningMessage
            id="warning_no_threshold_selected"
            type={MessageTypesEnum.ERROR}
            message={getLocalized('error_no_delay_very_low_msg')}
          />
        )}
      </FlexColumn>
      {showModal && (
        <DelayVeryLowMessageModal
          getLocalized={getLocalized}
          onClose={() => setShowModal(false)}
          value={tableData.map((x) => x.rowConfig.value)}
          onChange={setData}
        />
      )}
    </>
  )
}

export default memo(DelayVeryLowMessageControl)
