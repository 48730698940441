import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Table, Button, IconCheckmarkCircle } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { ArrayAttributeDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import CopyStampItems from './CopyStampsItems'
import { FlexColumn, TextBlack, PreviewLabel } from 'styles/styles'
import 'styles/global.scss'
import './index.scss'
import StampEditModal, { CopyStampEnum } from './StampEditModal'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import WarningMessage from '../WarningMessage'
import { TABLE_CONSTANTS } from 'common/utilities'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import ColorItems from 'context/policies/dropboxItems/ColorItems'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

const columnId = 'id'
const columnConfig = 'config'

const tablePreferences = {
  width: [
    { columnId: CopyStampEnum.POSITION, width: 120 },
    { columnId: CopyStampEnum.CONTENT, width: 200 },
    { columnId: CopyStampEnum.START, width: 80 },
    { columnId: CopyStampEnum.FONT, width: 200 },
    { columnId: CopyStampEnum.WHITE_BG, width: 100 },
    { columnId: columnConfig, width: 110 }
  ]
}

const defaultData = (position) => {
  return {
    [CopyStampEnum.POSITION]: position,
    [CopyStampEnum.CONTENT]: [],
    [CopyStampEnum.START]: '1',
    [CopyStampEnum.FONT]: 'Letter Gothic',
    [CopyStampEnum.SIZE]: '12',
    [CopyStampEnum.COLOR]: 'color.black',
    [CopyStampEnum.WHITE_BG]: false
  }
}

const requiredIds = [
  CopyStampEnum.START,
  CopyStampEnum.FONT,
  CopyStampEnum.SIZE,
  CopyStampEnum.COLOR
]

const CopyStampsControl = (props) => {
  const description: ArrayAttributeDescription = props.description
  const {
    compliance,
    attributes,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    addError,
    removeError,
    displayAllErrors
  } = useContext(settingsContext)

  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const isAnyRequiredFieldEmpty = () => {
    return !value.every(
      (e) =>
        Array.isArray(e[CopyStampEnum.CONTENT]) &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e[CopyStampEnum.CONTENT] as Array<any>).length > 0 &&
        requiredIds.every((id) => !!e[id])
    )
  }

  const enabled = isEnabled(attribute)
  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const [selectedStamp, setSelectedStamp] = useState(null)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    setValue(PoliciesHelper.getData(description, deviceSettings))
  }, [deviceSettings])

  const showError = displayError || displayAllErrors

  useEffect(() => {
    const error = isAnyRequiredFieldEmpty()
    const err = enabled && error
    setError(showError && err)
    err
      ? addError(props.id, attribute, showError)
      : removeError(props.id, attribute)
  }, [enabled, value, showError])

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const stamps = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(stamps) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const getTableData = (v) => {
    const getFont = (x) => {
      const color = ColorItems.stampColors.find(
        ({ value }) => value === x[CopyStampEnum.COLOR]
      )
      return getLocalized('font-summary', {
        font: x[CopyStampEnum.FONT] || TABLE_CONSTANTS.NO_DATA,
        size: x[CopyStampEnum.SIZE] || TABLE_CONSTANTS.NO_DATA,
        color: color ? getLocalized(color.label) : TABLE_CONSTANTS.NO_DATA
      })
    }
    const getContent = (x) =>
      x[CopyStampEnum.CONTENT]
        .map((y) => {
          const content = CopyStampItems.stampContents.find(
            ({ value }) => value === y
          )
          return content ? getLocalized(content.label) : y
        })
        .join(', ')
    const getWhiteBg = (x) =>
      getLocalized(x[CopyStampEnum.WHITE_BG] ? 'common.on' : 'common.off')
    const getConfig = (val, edit) => (
      <Button
        small={true}
        appearance={'ghost'}
        onClick={() => setSelectedStamp(val)}
      >
        {getLocalized(edit ? 'common.edit' : 'common.set')}
      </Button>
    )

    return CopyStampItems.stampPositions.map((pos) => {
      const item = v.find((x) => x[CopyStampEnum.POSITION] === pos.value)
      const bra = {
        [columnId]: pos.value,
        [CopyStampEnum.POSITION]: getLocalized(pos.label),
        [columnConfig]: getConfig(pos.value, !!item),
        rowConfig: { item }
      }
      const ket = item
        ? {
            [CopyStampEnum.CONTENT]:
              getContent(item) || TABLE_CONSTANTS.NO_DATA,
            [CopyStampEnum.START]:
              item[CopyStampEnum.START] || TABLE_CONSTANTS.NO_DATA,
            [CopyStampEnum.FONT]: getFont(item),
            [CopyStampEnum.WHITE_BG]: getWhiteBg(item)
          }
        : {
            content: TABLE_CONSTANTS.NO_DATA,
            start: TABLE_CONSTANTS.NO_DATA,
            font: TABLE_CONSTANTS.NO_DATA,
            whiteBg: TABLE_CONSTANTS.NO_DATA
          }
      return { ...bra, ...ket }
    })
  }

  useEffect(() => {
    setTableData(getTableData(value))
  }, [value])

  const setData = (newValue) => {
    setTableData(getTableData(newValue))
    setValue(newValue)
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const preview = () => (
    <>
      {tableHeader(getLocalized('stamps-position-preview'))}
      <div className="stampPreviewBox marginBottom16">
        {CopyStampItems.stampPositions.map((item) => (
          <IconCheckmarkCircle
            className="stampCheckIconColor marginBottom8 marginTop8"
            key={item.value}
            filled={!!getByPosFromTableData(item.value)}
          />
        ))}
      </div>
    </>
  )
  const onChange = (v) => {
    setDisplayError(true)
    const index = value.findIndex(
      (x) => x[CopyStampEnum.POSITION] === v[CopyStampEnum.POSITION]
    )
    if (index < 0) {
      if (v[CopyStampEnum.CONTENT].length) {
        setData([...value, v]) //Add
      }
    } else {
      setData(
        v[CopyStampEnum.CONTENT].length
          ? [...value.slice(0, index), v, ...value.slice(index + 1)] // Replace
          : [...value.slice(0, index), ...value.slice(index + 1)] // Remove
      )
    }
  }

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{text}</PreviewLabel>
    ) : (
      <TextBlack className={'marginBottom12'} disabled={!enabled}>
        {text}
      </TextBlack>
    )

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    const columns = [
      { id: columnId, label: columnId, index },
      { id: CopyStampEnum.POSITION, label: getLocalized('stamp-position') },
      { id: CopyStampEnum.CONTENT, label: getLocalized('stamp-content') },
      { id: CopyStampEnum.START, label: getLocalized('stamp-start') },
      { id: CopyStampEnum.FONT, label: getLocalized('stamp-font') },
      { id: CopyStampEnum.WHITE_BG, label: getLocalized('stamp-white-bg') },
      { id: columnConfig, label: getLocalized('stamp-config') }
    ]
    return onAttributeChange ? columns : columns.slice(0, columns.length - 1)
  }, [])

  const getByPosFromTableData = (stamp) =>
    tableData.find((item) => item.id === stamp)?.rowConfig.item

  return (
    <>
      <FlexColumn>
        {preview()}
        {tableHeader(getLocalized('copy-stamps'))}
        <Table
          columns={tableColumns}
          data={tableData}
          className={'widthColAuto'}
          data-testid={'id-copy-stamps-table'}
          preferences={tablePreferences}
          i18n={Retrievei18nItems()}
        />
        {error && (
          <WarningMessage
            id={attribute + '.error'}
            type={MessageTypesEnum.ERROR}
            message={getLocalized('common.errors.not-selected')}
          />
        )}
        {compliance && (
          <PreviewItem compliance={compliance} className={'marginTop4'} />
        )}
      </FlexColumn>
      {selectedStamp && (
        <StampEditModal
          value={
            getByPosFromTableData(selectedStamp) || defaultData(selectedStamp)
          }
          getLocalized={getLocalized}
          onChange={onChange}
          onClose={() => {
            setSelectedStamp(null)
          }}
        />
      )}
    </>
  )
}

export default memo(CopyStampsControl)
