import React, { useEffect, useState, useMemo } from 'react'
import { Button, IconArrowDown, IconArrowUp, Table } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { FlexRow, FlexColumn, TextBlack, PreviewLabel } from 'styles/styles'
import { getRowSelectAllState, TABLE_CONSTANTS } from 'common/utilities'
import { outgoingServerEnum, usageItems } from './OutgoingServersModal'
import 'styles/global.scss'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'

const OutgoingServersTable = (props) => {
  const { tableData, setTableData, usage, enabled, getLocalized } = props
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)

  const title = useMemo(
    () => usageItems.find(({ id }) => id === usage)?.label,
    [usage]
  )

  useEffect(() => {
    setRowSelectAllState(
      getRowSelectAllState(
        tableData.filter((x) => x.rowConfig.selected).length,
        tableData.length
      )
    )
  }, [tableData])

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: TABLE_CONSTANTS.ID, label: TABLE_CONSTANTS.ID, index },
      {
        id: outgoingServerEnum.ADDRESS,
        label: getLocalized('server-' + outgoingServerEnum.ADDRESS)
      },
      {
        id: outgoingServerEnum.PORT,
        label: getLocalized('server-' + outgoingServerEnum.PORT)
      },
      {
        id: outgoingServerEnum.USAGE,
        label: getLocalized('server-' + outgoingServerEnum.USAGE)
      }
    ]
  }, [])

  const swapArrayItems = (a, i, j) => {
    const temp = a[i]
    a[i] = a[j]
    a[j] = temp
  }

  const onMoveUp = (simulate = false) => {
    let moved = 0
    for (let i = 1; i < tableData.length; i++) {
      if (
        tableData[i].rowConfig.selected &&
        !tableData[i - 1].rowConfig.selected
      ) {
        if (simulate) {
          return true
        }
        swapArrayItems(tableData, i - 1, i)
        moved++
      }
    }
    if (moved) {
      setTableData([...tableData], false)
    }
    return moved > 0
  }

  const onMoveDown = (simulate = false) => {
    let moved = 0
    for (let i = tableData.length - 2; i >= 0; i--) {
      if (
        tableData[i].rowConfig.selected &&
        !tableData[i + 1].rowConfig.selected
      ) {
        if (simulate) {
          return true
        }
        swapArrayItems(tableData, i, i + 1)
        moved++
      }
    }
    if (moved) {
      setTableData([...tableData], false)
    }
    return moved > 0
  }

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const found = tableData.find(({ id }) => id === rowId)
    if (found) {
      found.rowConfig.selected = checked
      setTableData([...tableData], true) // expand selection to other tables
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    const newTableData = tableData.map((row) => {
      const rowCopy = row
      row.rowConfig.selected = checked
      return rowCopy
    })
    setTableData(newTableData, true) // expand selection to other tables
  }

  const tableHeader = (text) =>
    !setTableData ? (
      <PreviewLabel className={'paddingTop16'}>{text}</PreviewLabel>
    ) : (
      <TextBlack className={'paddingTop16 marginBottom4'} disabled={!enabled}>
        {text}
      </TextBlack>
    )

  return (
    <FlexColumn>
      {tableHeader(getLocalized(title))}
      <FlexRow>
        <Table
          columns={tableColumns}
          data={tableData}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          rowSelector={setTableData && enabled ? 'multiSelection' : undefined}
          rowSelectAllState={rowSelectAllState}
          className={'widthColAuto'}
          data-testid={'id-outgoing-services-table-' + usage}
          preferences={{
            width: [
              { columnId: outgoingServerEnum.ADDRESS, width: 140 },
              { columnId: outgoingServerEnum.PORT, width: 60 },
              { columnId: outgoingServerEnum.USAGE, width: 140 }
            ]
          }}
          i18n={Retrievei18nItems()}
        />
        {setTableData && (
          <FlexColumn className={'marginLeft8'}>
            <Button
              appearance={'ghost'}
              leadingIcon={<IconArrowUp />}
              onClick={() => onMoveUp()}
              disabled={!enabled || !onMoveUp(true)}
            />
            <Button
              appearance={'ghost'}
              leadingIcon={<IconArrowDown />}
              onClick={() => onMoveDown()}
              disabled={!enabled || !onMoveDown(true)}
            />
          </FlexColumn>
        )}
      </FlexRow>
    </FlexColumn>
  )
}

export default OutgoingServersTable
