import React, { useContext } from 'react'
import { Button, ButtonGroup } from '@veneer/core'
import configContext from 'context/config/configContext'
import 'styles/global.scss'
import './index.scss'

const Footer = (props) => {
  const { t } = useContext(configContext)
  const { onSave, onCancel, error, isWex } = props

  return (
    <div>
      <div
        id={'policy-footer'}
        className={`policy-fixed ${isWex ? 'colorHpBgWx' : 'colorHpBg'}`}
      >
        <div className={'policy-buttons-fixed'}>
          <ButtonGroup>
            <Button
              id={'cancelButton'}
              appearance={'secondary'}
              onClick={onCancel}
            >
              {t('common.cancel')}
            </Button>
            <Button id={'saveButton'} onClick={onSave} disabled={error}>
              {t('common.save')}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  )
}

export default Footer
