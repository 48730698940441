import React, { Fragment, useContext } from 'react'
import uuid from 'react-uuid'

import { Button, ButtonGroup, IconWarningAlt, Modal } from '@veneer/core'
import configContext from 'context/config/configContext'
import { FlexRow } from 'styles/styles'

const ConfirmSave = (props) => {
  const { t } = useContext(configContext)

  const getLocalized = (key: string): string => {
    return t(`policy.details.footer.saveModal.${key}`)
  }

  const newLineText = (text) => {
    return text.split('\n').map((str) => (
      <Fragment key={uuid()}>
        {str}
        <br />
      </Fragment>
    ))
  }

  return (
    <Modal
      onClose={props.onCancel}
      closeOnBlur={false}
      show={props.show}
      className={'small-policy-modal'}
      data-testid={'id-confirm-modal'}
      footer={
        <ButtonGroup>
          <Button id={'idConfirm'} onClick={props.onConfirm}>
            {t('common.confirm')}
          </Button>
          <Button
            id={'idCancelSave'}
            appearance={'secondary'}
            onClick={props.onCancel}
          >
            {t('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <FlexRow className={'marginBottom20 alignCenter'}>
        <IconWarningAlt filled color={'darkOrange5'} size={36} key={uuid()} />
        <h4 className={'marginLeft8'} data-testid={'id-confirm-modal-title'}>
          {getLocalized('title')}
        </h4>
      </FlexRow>
      <p className={'paddingBottom16'} data-testid={'id-confirm-modal-text-1'}>
        {newLineText(getLocalized('description'))}
      </p>
      <p className={'cant-undone'} data-testid={'id-confirm-modal-text-2'}>
        {getLocalized('confirmation')}
      </p>
    </Modal>
  )
}

export default ConfirmSave
