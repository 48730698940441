import React, { useContext, useEffect, useMemo, useState, memo } from 'react'
import { Button, ButtonGroup, Table } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import configContext from 'context/config/configContext'
import settingsContext from 'context/settings/settingsContext'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import {
  FwUpdateVersionDescription,
  Item
} from 'context/policies/PoliciesConfiguration'
import LabelRadioButtons from 'common/controls/labelRadioButtons'
import LabelSelect from 'common/controls/labelSelect'
import { getRowSelectAllState } from 'common/utilities'
import FwUpdateVersionModal, {
  FwUpdateEnum,
  isThisModel,
  getSoarVersions
} from './FwUpdateVersionModal'
import { FlexColumn, FlexRow, MaxTableHeight, TextBlack } from 'styles/styles'
import WarningMessage from '../WarningMessage'
import PreviewItem from '../../previewItem'
import configData from 'common/config/settings.json'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import 'styles/global.scss'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

const anyModel = 'any'
const fwuAnyLatest = {
  [FwUpdateEnum.MODELS]: [anyModel],
  [FwUpdateEnum.VERSION]: 'latest'
}

enum RadioItemEnum {
  LATEST = 'latest',
  SPECIFIC = 'specific'
}

const tablePreferences = {
  width: [
    { columnId: FwUpdateEnum.UI_MODEL, width: 320 },
    { columnId: FwUpdateEnum.UI_VERSION, width: 320 }
  ]
}

const FwUpdateVersionControl = (props) => {
  const description: FwUpdateVersionDescription = props.description
  const {
    id,
    compliance,
    attributes,
    localizationPath,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute, model } = description

  const { tt } = useContext(configContext)
  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    addError,
    removeError,
    displayAllErrors
  } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)

  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )

  const latestVer: Item = {
    label: getLocalized('latest-version'),
    value: fwuAnyLatest[FwUpdateEnum.VERSION]
  }

  const [modelVersions, setModelVersions] = useState(null)
  const [tableData, setTableData] = useState([])
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [selectedCount, setSelectedCount] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const showError = displayError || displayAllErrors

  const enabled = isEnabled(attribute)
  const errorState = enabled && !value.length
  useEffect(() => {
    setError(showError && errorState)
    errorState ? addError(id, attribute, showError) : removeError(id, attribute)
  }, [errorState, showError])

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  const version = useMemo(
    () => (model ? value.find((x) => isThisModel(x, model)) : undefined),
    [model, value]
  )

  useEffect(() => {
    if (onAttributeChange && model && !modelVersions) {
      const versions = [latestVer]
      if (version && version[FwUpdateEnum.VERSION] !== latestVer.value) {
        versions.push({
          label: version[FwUpdateEnum.UI_VERSION],
          value: version[FwUpdateEnum.VERSION]
        })
      }
      setModelVersions(versions)
      getSoarVersions(model, (_, versions) =>
        setModelVersions([latestVer, ...versions])
      )
    }
  }, [version])

  const setData = (newTableData) => {
    setTableData(newTableData)
    const settings = [...deviceSettings]
    const newValue = newTableData.map((x) => x.rowConfig.item)
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const versions = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(versions) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const radioItems = useMemo(
    () =>
      Object.values(RadioItemEnum).map((value) => ({
        value,
        label: getLocalized(`version-${value}`)
      })),
    []
  )

  const onVersion = (option) => {
    const knownModel = configData.fwuModels.find((x) =>
      x[FwUpdateEnum.NUMBERS].includes(model)
    )
    setData([
      tableItem(
        {
          [FwUpdateEnum.MODELS]: [model],
          [FwUpdateEnum.UI_MODEL]: knownModel?.[FwUpdateEnum.NAME] || model,
          [FwUpdateEnum.VERSION]: option.value,
          [FwUpdateEnum.UI_VERSION]: option.label
        },
        0
      )
    ])
  }

  const onRemove = () => {
    setData(tableData.filter((x) => !x.rowConfig.selected))
    setDisplayError(true)
  }

  const tableItem = (item, i) => {
    const selected = i < tableData.length && !!tableData[i].rowConfig.selected
    return {
      id: i,
      [FwUpdateEnum.UI_MODEL]: item[FwUpdateEnum.UI_MODEL],
      [FwUpdateEnum.UI_VERSION]: item[FwUpdateEnum.UI_VERSION],
      rowConfig: { selected, item }
    }
  }

  useEffect(
    () => setTableData(value ? value.map((x, i) => tableItem(x, i)) : []),
    [value]
  )

  const selected = useMemo(
    () =>
      +!value.some(
        (x) =>
          x[FwUpdateEnum.VERSION] === fwuAnyLatest[FwUpdateEnum.VERSION] &&
          (isThisModel(x, anyModel) || (model && isThisModel(x, model)))
      ),
    [value, model]
  )

  useEffect(() => {
    const selected = tableData.filter((x) => x.rowConfig.selected).length
    setSelectedCount(selected)
    setRowSelectAllState(getRowSelectAllState(selected, tableData.length))
  }, [tableData])

  const tableColumns = useMemo(() => {
    const id = 'id'
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id, label: id, index },
      { id: FwUpdateEnum.UI_MODEL, label: getLocalized('model-name') },
      { id: FwUpdateEnum.UI_VERSION, label: getLocalized('firmware-version') }
    ]
  }, [])

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    setTableData(
      tableData.map((row) => {
        row.rowConfig.selected = checked
        return row
      })
    )
  }

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const found = tableData.find(({ id }) => id === rowId)
    if (found) {
      found.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  const disabled = !enabled || !selected
  const i18nItems = Retrievei18nItems()
  return (
    <FlexColumn>
      {!onAttributeChange ? (
        <PreviewItem
          label={`${localizationPath}.version`}
          value={
            model && selected
              ? version?.[FwUpdateEnum.UI_VERSION]
              : radioItems[selected].label
          }
          compliance={compliance}
        />
      ) : model ? (
        <LabelSelect
          id={'id-fw-update-versions'}
          disabled={!enabled}
          className={'maxTextWidth'}
          label={getLocalized('version')}
          placeholder={getLocalized('common.select-option')}
          error={error}
          helperText={error ? getLocalized('common.errors.not-selected') : null}
          value={[version?.[FwUpdateEnum.VERSION]]}
          options={modelVersions || []}
          onChange={onVersion}
        />
      ) : (
        <LabelRadioButtons
          disabled={!enabled}
          label={getLocalized('version')}
          id={'id-fw-update-version'}
          value={radioItems[selected].value}
          options={radioItems}
          onChange={(_, val) =>
            setData(
              val === RadioItemEnum.LATEST ? [tableItem(fwuAnyLatest, 0)] : []
            )
          }
        />
      )}
      {!model && (onAttributeChange || selected === 1) && (
        <FlexColumn
          className={`paddingTop16${
            onAttributeChange ? ' devSettingsIndent1' : ''
          }`}
        >
          {onAttributeChange && (
            <FlexRow className={'marginBottom12 alignCenter'}>
              <ButtonGroup>
                <Button
                  appearance={'secondary'}
                  disabled={disabled}
                  onClick={() => setShowModal(true)}
                  id={'fw-update-add'}
                >
                  {getLocalized('common.add')}
                </Button>
                <Button
                  appearance={'secondary'}
                  disabled={disabled || !selectedCount}
                  onClick={onRemove}
                  id={'fw-update-remove'}
                >
                  {getLocalized('common.remove')}
                </Button>
              </ButtonGroup>
              <TextBlack className={'marginLeft12'} disabled={disabled}>
                {getLocalized('n-versions-added', {
                  count: selected ? tableData.length : 0
                })}
              </TextBlack>
            </FlexRow>
          )}
          <MaxTableHeight>
            <Table
              columns={tableColumns}
              data={selected ? tableData : []}
              onSelect={handleRowSelect}
              onSelectAllPageItems={handleSelectAllPageItems}
              rowSelector={
                onAttributeChange && !disabled ? 'multiSelection' : undefined
              }
              rowSelectAllState={rowSelectAllState}
              data-testid={'id-fw-update-table'}
              preferences={tablePreferences}
              i18n={i18nItems}
            />
          </MaxTableHeight>
          {error && (
            <WarningMessage
              id={attribute + '.error'}
              type={MessageTypesEnum.ERROR}
              message={getLocalized('error-not-selected')}
            />
          )}
        </FlexColumn>
      )}
      {showModal && (
        <FwUpdateVersionModal
          getLocalized={getLocalized}
          latestVer={latestVer}
          value={value}
          onClose={() => setShowModal(false)}
          onChange={(v) => setData(v.map((x, i) => tableItem(x, i)))}
        />
      )}
    </FlexColumn>
  )
}

export default memo(FwUpdateVersionControl)
