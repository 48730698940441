import { Button, ButtonGroup, Table } from '@veneer/core'
import {
  ColumnIndexTypes,
  SortTypes,
  TableSortBy,
  TableData
} from '@veneer/core/dist/scripts/table'
import configContext from 'context/config/configContext'
import { SimpleTableDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import settingsContext from 'context/settings/settingsContext'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FlexColumn, FlexRow, PreviewLabel, TextBlack } from 'styles/styles'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import WarningMessage from '../WarningMessage'
import SimpleTableModal from './SimpleTableModal'
import { abcSort, getRowSelectAllState } from 'common/utilities'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

const noSelection = -1
const columnId = 'id'
const columnName = 'column'
const defSortOrder: SortTypes = 'ascending'
const defSorting: TableSortBy = {
  id: columnName,
  type: defSortOrder
}

const SimpleTableControl = (props) => {
  const description: SimpleTableDescription = props.description
  const {
    compliance,
    attributes,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const {
    label,
    attribute,
    limit,
    emptyAllowed,
    errors,
    countLabel,
    tableColumn,
    modalDescription,
    modalTextLabel,
    modalHelperText,
    duplicateError
  } = description
  const { tt } = useContext(configContext)

  const {
    addDisabled,
    removeDisabled,
    addError,
    removeError,
    isEnabled,
    displayAllErrors
  } = useContext(settingsContext)
  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const [sorting, setSorting] = useState(defSorting)
  const [tableData, setTableData] = useState([])
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const enabled = isEnabled(attribute)
  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const [selectedIndex, setSelectedIndex] = useState(noSelection)
  const [showModal, setShowModal] = useState(false)
  const [selectedCount, setSelectedCount] = useState(0)
  const showError = displayError || displayAllErrors
  const emptyArray = !value.length && !emptyAllowed

  useEffect(() => {
    setTableData(value ? value.map((x, index) => tableItem(x, index)) : [])
  }, [value])

  useEffect(() => {
    const attributeErrors = enabled && emptyArray
    setError(showError && attributeErrors)
    attributeErrors
      ? addError(props.id, attribute, showError)
      : removeError(props.id, attribute)
  }, [enabled, emptyArray, showError])

  useEffect(() => {
    setValue(PoliciesHelper.getData(description, deviceSettings))
  }, [deviceSettings])

  useEffect(() => {
    const selected = tableData.filter((x) => x.rowConfig.selected).length
    setSelectedCount(selected)
    setRowSelectAllState(getRowSelectAllState(selected, tableData.length))
  }, [tableData])

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const val = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && val.toString() === value.toString()) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{text}</PreviewLabel>
    ) : (
      <TextBlack className={'marginBottom16'} disabled={!enabled}>
        {text}
      </TextBlack>
    )

  const onAdd = () => {
    if (!limit || tableData.length <= limit) {
      setSelectedIndex(noSelection)
      setShowModal(true)
    }
  }

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const found = tableData.find(({ id }) => id === rowId)
    if (found) {
      found.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  const onRemove = () => setData(tableData.filter((x) => !x.rowConfig.selected))

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: columnId, label: columnId, index },
      { id: columnName, label: getLocalized(tableColumn), sortable: true }
    ]
  }, [])

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    const newTableData = tableData.map((row) => {
      const rowCopy = row
      row.rowConfig.selected = checked
      return rowCopy
    })
    setTableData(newTableData)
  }

  const onChange = (val) => {
    setData(
      selectedIndex === noSelection
        ? [...tableData, tableItem(val, tableData.length)]
        : [
            ...tableData.slice(0, selectedIndex),
            tableItem(val, selectedIndex),
            ...tableData.slice(selectedIndex + 1)
          ]
    )
  }

  const setData = (newTableData) => {
    setTableData(newTableData)
    setDisplayError(true)
    const settings = [...deviceSettings]
    const newValue = newTableData.map((x) => x.rowConfig.item)
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const tableItem = (item, i) => {
    const selected = i < tableData.length && !!tableData[i].rowConfig.selected
    return {
      id: i,
      column: item,
      rowConfig: { selected, item }
    }
  }

  const sortedData = useMemo(() => {
    return abcSort(tableData, (x) => x.column, sorting.type) as TableData[]
  }, [tableData, sorting.type])

  return (
    <FlexColumn>
      {tableHeader(getLocalized(label))}
      {onAttributeChange && (
        <FlexRow className={'marginBottom12 alignCenter'}>
          <ButtonGroup>
            <Button
              appearance={'secondary'}
              disabled={!enabled || tableData.length >= limit}
              onClick={() => onAdd()}
              id={'add'}
            >
              {getLocalized('common.add')}
            </Button>
            <Button
              appearance={'secondary'}
              disabled={!enabled || !selectedCount}
              onClick={onRemove}
              id={'remove'}
            >
              {getLocalized('common.remove')}
            </Button>
          </ButtonGroup>
          <TextBlack disabled={!enabled} className={'marginLeft12'}>
            {getLocalized(countLabel, {
              count: tableData.length,
              max: limit
            })}
          </TextBlack>
        </FlexRow>
      )}
      <Table
        columns={tableColumns}
        data={sortedData}
        onSort={(_, sortBy) => setSorting(sortBy)}
        onSelect={handleRowSelect}
        onSelectAllPageItems={handleSelectAllPageItems}
        rowSelector={
          onAttributeChange && enabled ? 'multiSelection' : undefined
        }
        rowSelectAllState={rowSelectAllState}
        className={'widthColAuto'}
        data-testid={'id-simple-table'}
        preferences={{
          sortBy: sorting,
          width: [{ columnId: columnName, width: 240 }]
        }}
        i18n={Retrievei18nItems()}
      />
      {error && (
        <WarningMessage
          id={attribute + '.error'}
          type={MessageTypesEnum.ERROR}
          message={getLocalized('common.errors.not-selected')}
        />
      )}
      {compliance && (
        <PreviewItem compliance={compliance} className={'marginTop4'} />
      )}
      {showModal && (
        <SimpleTableModal
          value={
            selectedIndex !== noSelection
              ? tableData[selectedIndex].rowConfig.item
              : ''
          }
          edit={selectedIndex !== noSelection}
          onChange={(v) => onChange(v)}
          onClose={() => setShowModal(false)}
          getLocalized={getLocalized}
          errors={errors}
          tableData={tableData}
          limit={limit}
          modalDescription={modalDescription}
          modalTextLabel={modalTextLabel}
          modalHelperText={modalHelperText}
          duplicateError={duplicateError}
        />
      )}
    </FlexColumn>
  )
}

export default SimpleTableControl
