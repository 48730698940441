import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  ButtonGroup,
  ColumnIndexTypes,
  IconApplication,
  IconFolder,
  Modal,
  Table
} from '@veneer/core'
import { HomeScreenAppsEnum, LauncherTypeEnum } from './HomeScreenAppsMenu'
import { FlexRow, FlexRowWithSpace, ModalTableHeight } from 'styles/styles'
import { getRowSelectAllState } from 'common/utilities'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import 'components/policies/modal/index.scss'
import 'styles/global.scss'

const HomeScreenAppsAddModal = (props) => {
  const { value, onClose, onChange, getLocalized, getItemName } = props
  const [tableData, setTableData] = useState([])
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [selectedCount, setSelectedCount] = useState(0)

  const handleRowSelect = (event, rowId) => {
    const found = tableData.find(({ id }) => id === rowId)
    if (found) {
      found.rowConfig.selected = event.target.checked
      setTableData([...tableData])
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    setTableData(
      tableData.map((row) => {
        row.rowConfig.selected = checked
        return row
      })
    )
  }

  useEffect(() => {
    const selected = tableData?.filter((x) => x.rowConfig.selected).length
    setSelectedCount(selected)
    setRowSelectAllState(getRowSelectAllState(selected, tableData?.length))
  }, [tableData])

  const tableColumns = useMemo(() => {
    const id = 'id'
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id, label: id, index },
      {
        id: HomeScreenAppsEnum.NAME,
        label: getLocalized('name')
      },
      {
        id: HomeScreenAppsEnum.TYPE,
        label: getLocalized('type')
      }
    ]
  }, [])

  const handleAdd = () => {
    onChange(tableData.filter((v) => v.rowConfig.selected).map(({ id }) => id))
    onClose()
  }

  const tableItem = (item, id) => {
    const isApp = item[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.APP
    const isSystem = !item[HomeScreenAppsEnum.NAME]
    const type = () => {
      if (isApp) {
        return 'type-app'
      }
      return isSystem ? 'type-system-folder' : 'type-folder'
    }
    return {
      id,
      [HomeScreenAppsEnum.NAME]: (
        <FlexRow className={'alignCenter'}>
          {isApp ? <IconApplication /> : <IconFolder />}
          <span className={'marginLeft4'}>{getItemName(item)}</span>
        </FlexRow>
      ),
      [HomeScreenAppsEnum.TYPE]: getLocalized(type()),
      rowConfig: { selected: false }
    }
  }

  useEffect(() => {
    setTableData(value.map((item, i) => tableItem(item, i)))
  }, [value])

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized('add-app')}
      className={'large-policy-modal'}
      data-testid={'id-add-app-modal'}
      footer={
        <FlexRowWithSpace className={'alignCenter fullWidth'}>
          <ButtonGroup>
            <Button disabled={!selectedCount} onClick={handleAdd}>
              {getLocalized('common.add')}
            </Button>
            <Button appearance={'secondary'} onClick={onClose}>
              {getLocalized('common.cancel')}
            </Button>
          </ButtonGroup>
          {getLocalized('common.n-items-selected', { count: selectedCount })}
        </FlexRowWithSpace>
      }
    >
      <p className={'paddingBottom16'}>{getLocalized('add-app-message')}</p>
      <ModalTableHeight>
        <Table
          columns={tableColumns}
          data={tableData || []}
          loading={!tableData}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          rowSelector={'multiSelection'}
          rowSelectAllState={rowSelectAllState}
          data-testid={'id-add-app-modal-table'}
          preferences={{
            width: [
              { columnId: HomeScreenAppsEnum.NAME, width: 250 },
              { columnId: HomeScreenAppsEnum.TYPE, width: 150 }
            ]
          }}
          i18n={Retrievei18nItems()}
        />
      </ModalTableHeight>
    </Modal>
  )
}

export default HomeScreenAppsAddModal
