import { IconCircle } from '@veneer/core'
import React, { useMemo } from 'react'
import { FlexColumn, FlexRow } from 'styles/styles'
import { ComplianceType } from 'common/deviceHelper'
import { TABLE_CONSTANTS, formatDate, DATE_FORMAT } from 'common/utilities'
import './index.scss'
import 'styles/global.scss'

const ComplianceSummary = (props) => {
  const { deviceData, allAttributes, getLocalized, policyName } = props

  const PolicyNames = useMemo(
    () =>
      deviceData?.policies
        ? deviceData.policies.map((policy) => policyName(policy.name))
        : [],
    [deviceData, policyName]
  )
  const statusIcon = (status) => {
    let iconColor
    switch (status) {
      case ComplianceType.NonCompliant:
        iconColor = 'red7'
        break
      case ComplianceType.Compliant:
        iconColor = 'green7'
        break
      case ComplianceType.Unknown:
      default:
        iconColor = 'gray7'
        break
    }
    return (
      <FlexRow>
        <div className={'paddingRight8'}>
          <IconCircle size={12} filled={true} color={iconColor} />
        </div>
        <div
          className={'colorGray12'}
          data-testid={'compliance-info-status-name'}
        >
          {getLocalized(deviceData.status)}
        </div>
      </FlexRow>
    )
  }

  const activatedSettings = () => {
    const settings = []
    const count = (x) =>
      allAttributes?.filter((item) => item.status === x).length

    let x = count(ComplianceType.Compliant)
    if (x) {
      settings.push(
        getLocalized('compliantSettingsCount', { compliantSettings: x })
      )
    }

    x = count(ComplianceType.NonCompliant)
    if (x) {
      settings.push(
        getLocalized('nonCompliantSettingsCount', { nonCompliantSettings: x })
      )
    }

    x = count(ComplianceType.Unknown)
    if (x) {
      settings.push(getLocalized('unknownCount', { unknownCount: x }))
    }

    return settings.join(', ')
  }

  return (
    <FlexColumn className={'paddingBottom8'}>
      <FlexRow className={'paddingBottom4'}>
        <div className={'divWidth'} data-testid={'compliance-info-policy'}>
          {getLocalized('policy')}
        </div>
        <div
          className={'colorGray12 wordBreakWord'}
          data-testid={'compliance-info-policy-name'}
        >
          {PolicyNames.length
            ? PolicyNames.join(', ')
            : TABLE_CONSTANTS.NO_DATA}
        </div>
      </FlexRow>
      <FlexRow className={'paddingBottom4'}>
        <div className={'divWidth'} data-testid={'compliance-info-lastrun'}>
          {getLocalized('lastRun')}
        </div>
        <div
          className={'colorGray12 wordBreakWord'}
          data-testid={'compliance-info-lastrun-value'}
        >
          {formatDate(deviceData?.lastRunAt, DATE_FORMAT.LAST_MODIFIED)}
        </div>
      </FlexRow>
      <FlexRow className={'paddingBottom4'}>
        <div className={'divWidth'} data-testid={'compliance-info-status'}>
          {getLocalized('status')}
        </div>
        {deviceData?.status && statusIcon(deviceData.status)}
      </FlexRow>
      <FlexRow>
        <div className={'divWidth'} data-testid={'compliance-info-total'}>
          {getLocalized('totalActiveSettings')}
        </div>
        {allAttributes?.length && (
          <div
            className={'colorGray12'}
            data-testid={'compliance-info-total-counts'}
          >
            {allAttributes.length} ({activatedSettings()})
          </div>
        )}
      </FlexRow>
    </FlexColumn>
  )
}
export default ComplianceSummary
