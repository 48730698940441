import React, { useEffect, useMemo, useState } from 'react'
import { Button, ButtonGroup, Modal, Table } from '@veneer/core'
import { SortTypes, TableData } from '@veneer/core/dist/scripts/table'
import { FlexRowWithSpace, ModalTableHeight } from 'styles/styles'
import 'components/policies/modal/index.scss'
import { abcSort, getRowSelectAllState } from 'common/utilities'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'

const columnId = 'label'
const orderDescending = 'descending' as SortTypes

const MultiSelectCheckboxModal = (props) => {
  const { items, value, onChange, onClose, l18n, id } = props

  const [tableData, setTableData] = useState([])
  const [orderType, setOrderType] = useState(orderDescending)
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [selectedCount, setSelectedCount] = useState(0)
  const [disabled, setDisabled] = useState(true)

  const testId = (suffix) => `${id}.${suffix}`

  useEffect(() => {
    setTableData(
      items.map((item, index) => {
        return {
          value: item.value,
          label: item.label,
          uid: index,
          rowConfig: { selected: value.includes(item.value) }
        }
      })
    )
  }, [items, value])

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const found = tableData.find((row) => row.uid === rowId)
    if (found) {
      found.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    const newTableData = tableData.map((row) => {
      const rowCopy = row
      row.rowConfig.selected = checked
      return rowCopy
    })
    setTableData(newTableData)
  }

  const selectedValues = () =>
    tableData.filter((x) => x.rowConfig.selected).map((x) => x.value)

  useEffect(() => {
    const items = selectedValues()
    setDisabled(
      !items.length ||
        (items.length === value.length && items.every((x) => value.includes(x)))
    )
    setSelectedCount(items.length)
    setRowSelectAllState(getRowSelectAllState(items.length, tableData.length))
  }, [tableData])

  const sortedTableData = useMemo(() => {
    return abcSort(tableData, (x) => x.label, orderType) as TableData[]
  }, [tableData, orderType])

  const handleSelect = () => {
    onChange(selectedValues())
    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={l18n.title}
      className={'small-policy-modal'}
      data-testid={testId('modal')}
      footer={
        <FlexRowWithSpace className={'alignCenter fullWidth'}>
          <ButtonGroup>
            <Button
              id={testId('select')}
              onClick={handleSelect}
              disabled={disabled}
            >
              {l18n.button}
            </Button>
            <Button
              id={testId('cancel')}
              appearance={'secondary'}
              onClick={onClose}
            >
              {l18n.cancel}
            </Button>
          </ButtonGroup>
          <p>{l18n.selected(selectedCount)}</p>
        </FlexRowWithSpace>
      }
    >
      <p className={'paddingBottom16'}>{l18n.description}</p>
      <ModalTableHeight>
        <Table
          columns={[
            { id: columnId, label: l18n.column },
            { id: 'uid', label: 'Id', index: 'hidden' }
          ]}
          data={sortedTableData}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          onSort={(_, { type }) => setOrderType(type)}
          rowSelector={'multiSelection'}
          rowSelectAllState={rowSelectAllState}
          preferences={{
            sortBy: { id: columnId, type: orderType },
            width: [{ columnId, width: 240 }]
          }}
          data-testid={testId('table')}
          i18n={Retrievei18nItems()}
        />
      </ModalTableHeight>
    </Modal>
  )
}
export default MultiSelectCheckboxModal
