import { Button, ButtonGroup, IconWarningAlt, Modal } from '@veneer/core'
import React, { useContext } from 'react'
import configContext from 'context/config/configContext'
import 'components/policies/modal/index.scss'
import { FlexRow } from 'styles/styles'

const ConfirmRemove = (props) => {
  const { t } = useContext(configContext)

  const key = (subkey: string): string => {
    return `policy.remove.${subkey}`
  }

  return (
    <Modal
      className={'small-policy-modal'}
      onClose={props.onCancel}
      closeOnBlur={false}
      show={props.show}
      data-testid={'id-remove-modal'}
      footer={
        <ButtonGroup>
          <Button
            colorScheme={'negative'}
            onClick={props.onRemove}
            id={'idDoRemove'}
          >
            {t('common.remove')}
          </Button>
          <Button
            appearance={'secondary'}
            onClick={props.onCancel}
            id={'idCancelRemove'}
          >
            {t('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <FlexRow className={'marginBottom20 alignCenter'}>
        <IconWarningAlt size={36} filled color={'darkOrange5'} />
        <h4 className={'marginLeft8'} data-testid={'id-remove-modal-title'}>
          {t(key('title'))}
        </h4>
      </FlexRow>
      <p className={'paddingBottom16'} data-testid={'id-remove-modal-text-1'}>
        {t(key('message'))}
      </p>
      <p data-testid={'id-remove-modal-text-2'}>{t(key('warning'))}</p>
    </Modal>
  )
}

export default ConfirmRemove
