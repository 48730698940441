import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import {
  Table,
  Button,
  ContextualMenu,
  IconApplication,
  IconFolder,
  ButtonGroup
} from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { HomeScreenAppsDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import HomeScreenAppsMenu, {
  LauncherTypeEnum,
  MenuActionEnum,
  HomeScreenAppsEnum
} from './HomeScreenAppsMenu'
import SystemLaunchers from './SystemLaunchers'
import ReferenceDeviceModal from 'components/policies/modal/ReferenceDevice'
import HomeScreenAppsFolderNameModal from './HomeScreenAppsFolderNameModal'
import HomeScreenAppsFolderSelectModal from './HomeScreenAppsFolderSelectModal'
import HomeScreenAppsFolderDeleteModal from './HomeScreenAppsFolderDeleteModal'
import HomeScreenAppsChangePageModal from './HomeScreenAppsChangePageModal'
import HomeScreenAppsAddModal from './HomeScreenAppsAddModal'
import HelpButton from 'components/policies/settings/attributes/HelpButton'
import { getRowSelectAllState } from 'common/utilities'
import { FlexRow, FlexColumn, TextBlack, PreviewLabel } from 'styles/styles'
import 'styles/global.scss'

const pageColumn = 'page'

const HomeScreenAppsControl = (props) => {
  const description: HomeScreenAppsDescription = props.description
  const { attribute, simple } = description
  const {
    compliance,
    attributes,
    data: { deviceSettings },
    onAttributeChange,
    localizationPath
  } = props
  const { isEnabled, addDisabled, removeDisabled } = useContext(settingsContext)
  const { tt } = useContext(configContext)

  const [value, setValue] = useState([])
  const [tableData, setTableData] = useState([])
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [selectedCount, setSelectedCount] = useState(0)
  const [addModal, setAddModal] = useState(false)
  const [deviceModal, setDeviceModal] = useState(false)
  const [menuModal, setMenuModal] = useState(null)

  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const apps = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(apps) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value as [],
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  const values = useMemo(() => {
    if (simple) {
      return { visible: value || [], hidden: [] }
    }
    const hidden = []
    const visible = []
    value?.forEach((page: []) => {
      const newPage = page.reduce((acc, cur) => {
        if (cur[HomeScreenAppsEnum.VISIBLE]) {
          acc.push(cur)
        } else {
          hidden.push(cur)
        }
        return acc
      }, [])
      if (newPage.length) {
        visible.push(newPage)
      }
    })
    return { visible, hidden }
  }, [value, simple])

  const setData = (newVisible) => {
    const newValue = newVisible.length
      ? [[...values.hidden, ...newVisible[0]], ...newVisible.slice(1)]
      : [[...values.hidden]]
    setValue(newValue)
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, newValue as [])
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const getItemName = (item) => {
    const key = SystemLaunchers.launcherKeys[item[HomeScreenAppsEnum.ID]]
    return key ? getLocalized(key) : item[HomeScreenAppsEnum.NAME]
  }

  const findData = (index) =>
    values.visible.reduce(
      (found, page: [], pageIndex) =>
        page.reduce((result, cur, curIndex, curPage) => {
          if (result) {
            return result
          }
          if (!index--) {
            return {
              index: curIndex,
              array: curPage,
              pages: values.visible,
              pageIndex
            }
          }
          if (cur[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.FOLDER) {
            const pages: [] = cur[HomeScreenAppsEnum.APPS]
            return pages.reduce(
              (foundApp, appPage: [], pageIndex) =>
                appPage.reduce((result, app, appIndex, appPage) => {
                  if (result) {
                    return result
                  }
                  if (!index--) {
                    return { index: appIndex, array: appPage, pages, pageIndex }
                  }
                  return undefined
                }, foundApp),
              undefined
            )
          }
          return undefined
        }, found),
      undefined
    )

  const findFolder = (pages, doIt: (page, index) => void) =>
    values.visible.some((page: Record<string, unknown>[]) => {
      const index = page.findIndex(
        (x) =>
          x[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.FOLDER &&
          x[HomeScreenAppsEnum.APPS] == pages
      )
      if (index >= 0) {
        doIt(page, index)
        return true
      }
      return false
    })

  const findAppInFolder = (id) =>
    values.visible.some(
      (page: Record<string, unknown>[]) =>
        !!page.find(
          (x) =>
            x[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.FOLDER &&
            (x[HomeScreenAppsEnum.APPS] as unknown[][]).some(
              (appPage) =>
                !!appPage.find((app) => app[HomeScreenAppsEnum.ID] === id)
            )
        )
    )

  const handleRowSelect = (event, rowId) => {
    let index = tableData.findIndex(({ id }) => id === rowId)

    const uncheckFolder = () => {
      while (--index >= 0 && tableData[index].rowConfig.indent) {
        /* empty */
      }
      if (index >= 0) {
        tableData[index].rowConfig.selected = false
      }
    }

    const checkApps = () => {
      while (++index < tableData.length && tableData[index].rowConfig.indent) {
        tableData[index].rowConfig.selected = true
      }
    }

    if (index >= 0) {
      const { checked } = event.target
      tableData[index].rowConfig.selected = checked
      if (!checked) {
        // if app inside folder is unchecked then folder should be unchecked also
        if (tableData[index].rowConfig.indent) {
          uncheckFolder()
        }
      } else if (tableData[index].rowConfig.isFolder) {
        // if folder is checked then all apps inside should be checked also
        checkApps()
      }
      setTableData([...tableData])
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    setTableData(
      tableData.map((row) => {
        row.rowConfig.selected = checked
        return row
      })
    )
  }

  const onAdd = (indexes) => {
    const newVisible = indexes.map((index) => {
      const item = values.hidden[index]
      item[HomeScreenAppsEnum.VISIBLE] = true
      return item
    })
    if (values.visible.length) {
      values.visible[0] = [...newVisible, ...values.visible[0]]
    } else {
      values.visible = [newVisible]
    }
    values.hidden = values.hidden.filter((_, index) => !indexes.includes(index))
    setData([...values.visible])
  }

  const onRemove = () => {
    let index = 0

    const processFolder = (cur) => {
      const appPages = cur[HomeScreenAppsEnum.APPS] as []
      cur[HomeScreenAppsEnum.APPS] = appPages.reduce((pages, appPage: []) => {
        const newApps = appPage.reduce((apps, app: Record<string, unknown>) => {
          app[HomeScreenAppsEnum.VISIBLE] =
            !tableData[index++].rowConfig.selected
          if (app[HomeScreenAppsEnum.VISIBLE]) {
            apps.push(app)
          } else {
            values.hidden.push(app)
          }
          return apps
        }, [])
        if (newApps.length) {
          pages.push(newApps)
        }
        return pages
      }, [])
    }

    const newVisible = values.visible.reduce((result, page: []) => {
      const newPage = page.reduce((acc, cur: Record<string, unknown>) => {
        cur[HomeScreenAppsEnum.VISIBLE] = !tableData[index++].rowConfig.selected
        const isFolder =
          cur[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.FOLDER
        if (cur[HomeScreenAppsEnum.VISIBLE]) {
          acc.push(cur)
        } else {
          values.hidden.push(
            // Just to be sure: remove all apps from hidden folder
            isFolder ? { ...cur, [HomeScreenAppsEnum.APPS]: [] } : cur
          )
        }
        if (isFolder) {
          processFolder(cur)
        }
        return acc
      }, [])
      if (newPage.length) {
        result.push(newPage)
      }
      return result
    }, [])
    setData(newVisible)
  }

  const onMenu = (item, action, opts = {}) => {
    switch (action) {
      case MenuActionEnum.FROM_FOLDER:
      case MenuActionEnum.COPY_FROM_FOLDER:
        opts = { findFolder }
        break
      case MenuActionEnum.COPY_TO_FOLDER:
        opts = { findAppInFolder }
        break
      case MenuActionEnum.TO_FOLDER:
      case MenuActionEnum.TO_NEW_FOLDER:
      case MenuActionEnum.RENAME_FOLDER:
      case MenuActionEnum.REMOVE_FOLDER:
      case MenuActionEnum.CHANGE_PAGE:
        if (!menuModal) {
          // show modal
          setMenuModal({ item, action })
          return
        }
        break
      // all other menu items do not require modal nor extra parameters
      default:
        break
    }
    if (HomeScreenAppsMenu.onMenu(item, action, opts)) {
      setData(values.visible)
    }
  }

  const renderMenuModal = () => {
    const { item, action } = menuModal
    switch (action) {
      case MenuActionEnum.TO_FOLDER: {
        const allItems = values.visible.reduce(
          (items, page) => [...items, ...page],
          []
        )
        const options = allItems
          .map((x, i) => ({
            label: getItemName(x),
            value:
              x[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.FOLDER &&
              x[HomeScreenAppsEnum.APPS] != item.pages // exclude this folder
                ? i + 1
                : 0
          }))
          .filter((x) => x.value)
        return (
          <HomeScreenAppsFolderSelectModal
            getLocalized={getLocalized}
            options={options}
            onClose={() => setMenuModal(null)}
            onChange={(folderIndex) =>
              onMenu(item, action, { folder: allItems[folderIndex - 1] })
            }
            appName={getItemName(item.array[item.index])}
          />
        )
      }

      case MenuActionEnum.TO_NEW_FOLDER:
      case MenuActionEnum.RENAME_FOLDER:
        return (
          <HomeScreenAppsFolderNameModal
            getLocalized={getLocalized}
            value={
              action === MenuActionEnum.RENAME_FOLDER
                ? item.array[item.index][HomeScreenAppsEnum.NAME]
                : null
            }
            onClose={() => setMenuModal(null)}
            onChange={(folderName) => onMenu(item, action, { folderName })}
          />
        )

      case MenuActionEnum.REMOVE_FOLDER:
        return (
          <HomeScreenAppsFolderDeleteModal
            getLocalized={getLocalized}
            onClose={() => setMenuModal(null)}
            onOk={() => onMenu(item, action)}
            folderName={item.array[item.index][HomeScreenAppsEnum.NAME]}
          />
        )

      case MenuActionEnum.CHANGE_PAGE:
        return (
          <HomeScreenAppsChangePageModal
            getLocalized={getLocalized}
            count={item.pages.length}
            value={item.pageIndex + 1}
            onClose={() => setMenuModal(null)}
            onChange={(pageNumber) => onMenu(item, action, { pageNumber })}
            appName={getItemName(item.array[item.index])}
          />
        )
    }
  }

  const tableItem = (
    index,
    item,
    pos: { index; length },
    pageNumber: number,
    count?: number,
    indent?: boolean
  ) => {
    const isApp = item[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.APP
    const isSystem = !item[HomeScreenAppsEnum.NAME]
    const name = getItemName(item)
    return {
      id: index,
      [HomeScreenAppsEnum.NAME]: (
        <FlexRow
          className={`alignCenter${indent ? ' devSettingsIndent1' : ''}`}
        >
          {isApp ? <IconApplication /> : <IconFolder />}
          <span className={'marginLeft4'}>
            {count ? getLocalized('name-n', { name, count }) : name}
          </span>
        </FlexRow>
      ),
      [HomeScreenAppsEnum.TYPE]: getLocalized(
        isApp ? 'type-app' : isSystem ? 'type-system-folder' : 'type-folder'
      ),
      [pageColumn]: pageNumber + 1,
      rowConfig: {
        indent,
        isFolder: !isApp,
        action: onAttributeChange && (
          <ContextualMenu
            id={index + '-menu'}
            placement={'top-end'}
            options={HomeScreenAppsMenu.menuOptions(
              getLocalized,
              pos,
              isApp,
              isSystem,
              indent,
              simple,
              () =>
                isApp ? findAppInFolder(item[HomeScreenAppsEnum.ID]) : false
            )}
            onClick={(_, option) => onMenu(findData(index), option.value)}
          />
        )
      }
    }
  }

  useEffect(() => {
    const items =
      values.visible?.reduce(
        (items: Record<string, unknown>[], page: [], pageNumber) =>
          page.reduce((acc, cur, curIndex, curPage) => {
            const appPages =
              cur[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.FOLDER
                ? cur[HomeScreenAppsEnum.APPS]
                : null
            acc.push(
              tableItem(
                acc.length,
                cur,
                { index: curIndex, length: curPage.length },
                pageNumber,
                appPages?.reduce((a, x) => a + x.length, 0)
              )
            )
            if (appPages) {
              appPages.forEach((appPage, i) =>
                appPage.forEach((app, j) =>
                  acc.push(
                    tableItem(
                      acc.length,
                      app,
                      { index: j, length: appPage.length },
                      i,
                      null,
                      true
                    )
                  )
                )
              )
            }
            return acc
          }, items),
        []
      ) || []
    setTableData(items)
  }, [values.visible])

  useEffect(() => {
    const selected = tableData.filter((x) => x.rowConfig.selected).length
    setSelectedCount(selected)
    setRowSelectAllState(getRowSelectAllState(selected, tableData.length))
  }, [tableData])

  const tableInfo = useMemo(() => {
    const id = 'id'
    const index: ColumnIndexTypes = 'hidden'
    const columns = [
      { id, label: id, index },
      { id: HomeScreenAppsEnum.NAME, label: getLocalized('name') },
      { id: HomeScreenAppsEnum.TYPE, label: getLocalized('type') },
      { id: pageColumn, label: getLocalized('page') }
    ]
    const width = [
      { columnId: HomeScreenAppsEnum.NAME, width: 250 },
      { columnId: HomeScreenAppsEnum.TYPE, width: 150 },
      { columnId: pageColumn, width: 100 }
    ]
    return simple
      ? {
          columns: columns.slice(0, 3),
          preferences: { width: width.slice(0, 2) }
        }
      : { columns, preferences: { width } }
  }, [simple])

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{getLocalized(text)}</PreviewLabel>
    ) : (
      <FlexRow className={'alignCenter marginBottom16'}>
        <TextBlack disabled={!enabled}>{getLocalized(text)}</TextBlack>
        <FlexRow className={'paddingLeft4'}>
          <HelpButton enabled description={getLocalized(text + '_help')} />
        </FlexRow>
      </FlexRow>
    )

  const i18nItems = Retrievei18nItems()
  const enabled = isEnabled(attribute)

  const onDeviceChange = (selectedDevice) => {
    // To do
    console.log(selectedDevice)
  }
  return (
    <>
      <FlexColumn>
        {onAttributeChange && (
          <FlexRow className={'marginBottom12'}>
            <Button
              appearance={'secondary'}
              id={'id-home-screen-apps-device'}
              onClick={() => setDeviceModal(true)}
            >
              {getLocalized('select-device')}
            </Button>
          </FlexRow>
        )}
        {tableHeader('home-screen')}
        {!simple && onAttributeChange && (
          <FlexRow className={'marginBottom12 alignCenter'}>
            <ButtonGroup>
              <Button
                appearance={'secondary'}
                disabled={!enabled || !values.hidden.length}
                onClick={() => setAddModal(true)}
                id={'addFolderApps'}
              >
                {getLocalized('common.add')}
              </Button>
              <Button
                appearance={'secondary'}
                disabled={!enabled || !selectedCount}
                onClick={onRemove}
                id={'removeFolderApps'}
              >
                {getLocalized('common.remove')}
              </Button>
            </ButtonGroup>
            <TextBlack className={'marginLeft12'} disabled={!enabled}>
              {getLocalized('n-items-added', {
                count: tableData.length
              })}
            </TextBlack>
          </FlexRow>
        )}
        <Table
          columns={tableInfo.columns}
          data={tableData}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          rowSelector={
            onAttributeChange && enabled && !simple
              ? 'multiSelection'
              : undefined
          }
          rowSelectAllState={rowSelectAllState}
          className={'widthColAuto paddingFourthCol4'}
          data-testid={'id-home-screen-apps-table'}
          preferences={tableInfo.preferences}
          i18n={i18nItems}
        />
        {compliance && (
          <PreviewItem compliance={compliance} className={'marginTop4'} />
        )}
      </FlexColumn>
      {addModal && (
        <HomeScreenAppsAddModal
          value={values.hidden}
          getLocalized={getLocalized}
          getItemName={getItemName}
          onChange={onAdd}
          onClose={() => setAddModal(false)}
        />
      )}
      {deviceModal && (
        <ReferenceDeviceModal
          onChange={(selectedDevice) => onDeviceChange(selectedDevice)}
          onClose={() => setDeviceModal(false)}
        />
      )}
      {menuModal && renderMenuModal()}
    </>
  )
}

export default memo(HomeScreenAppsControl)
