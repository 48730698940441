import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Button, ButtonGroup, Table } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { ArrayAttributeDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import { FlexRow, FlexColumn, TextBlack, PreviewLabel } from 'styles/styles'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import {
  QuickSetModalMode,
  QuickSetsEnum,
  QuickSetsMessageEnum,
  QuickSetsFileEnum
} from './QuickSetsEnums'
import { EmailMessageEnum } from '../EmailMessage/EmailMessageEnums'
import { FileNameEnum } from '../FileName/FileNameEnums'
import {
  getRowSelectAllState,
  TABLE_CONSTANTS,
  NO_SELECTION
} from 'common/utilities'
import 'styles/global.scss'
import QuickSetsModal from './QuickSetsModal'

const tablePreferences = {
  width: [
    { columnId: QuickSetsEnum.TITLE, width: 180 },
    { columnId: QuickSetsEnum.TYPE, width: 120 },
    { columnId: QuickSetsEnum.DESCRIPTION, width: 300 }
  ]
}

const defaultQuickSetObject = {
  [QuickSetsEnum.TITLE]: '',
  [QuickSetsEnum.DESCRIPTION]: '',
  [QuickSetsEnum.START]: 'quick-sets.sets.start.enter',
  [QuickSetsEnum.LOCATION]: 'quick-sets.sets.location.folder',
  [QuickSetsEnum.TYPE]: QuickSetsEnum.TYPE_EMAIL,
  [QuickSetsEnum.MESSAGE]: {
    [QuickSetsMessageEnum.RESTRICTIONS]: 'email-message.restrictions.type',
    [EmailMessageEnum.FROM]: 'message-recipient.default',
    [EmailMessageEnum.FROM + EmailMessageEnum.EDITABLE]: 'false',
    [EmailMessageEnum.DEF_FROM]: '',
    [EmailMessageEnum.DEF_NAME]: '',
    [EmailMessageEnum.TO]: 'message-recipient.blank',
    [EmailMessageEnum.TO + QuickSetsMessageEnum.ADDRESSES]: [],
    [EmailMessageEnum.TO + EmailMessageEnum.EDITABLE]: 'true',
    [EmailMessageEnum.CC]: 'message-recipient.blank',
    [EmailMessageEnum.CC + QuickSetsMessageEnum.ADDRESSES]: [],
    [EmailMessageEnum.CC + EmailMessageEnum.EDITABLE]: 'true',
    [EmailMessageEnum.BCC]: 'message-recipient.blank',
    [EmailMessageEnum.BCC + QuickSetsMessageEnum.ADDRESSES]: [],
    [EmailMessageEnum.BCC + EmailMessageEnum.EDITABLE]: 'true',
    [EmailMessageEnum.SUBJECT]: '',
    [EmailMessageEnum.SUBJECT + EmailMessageEnum.EDITABLE]: 'true',
    [EmailMessageEnum.MESSAGE]: '',
    [EmailMessageEnum.MESSAGE + EmailMessageEnum.EDITABLE]: 'true',
    [QuickSetsMessageEnum.SIGN]: 'true',
    [QuickSetsMessageEnum.SIGN + EmailMessageEnum.EDITABLE]: 'true',
    [QuickSetsMessageEnum.ENCRYPT]: 'true',
    [QuickSetsMessageEnum.ENCRYPT + EmailMessageEnum.EDITABLE]: 'true'
  },
  [QuickSetsEnum.FILE]: {
    [QuickSetsFileEnum.MEDIA_SIZE]: '',
    [QuickSetsFileEnum.COLOR_PREFERENCE]:
      'file-color-preference.color-black-auto',
    [QuickSetsFileEnum.QUALITY]: 'file-quality.medium',
    [QuickSetsFileEnum.TYPE]: 'file-type.pdf',
    [QuickSetsFileEnum.RESOLUTION]: '200',
    [QuickSetsFileEnum.COMPRESSION]: 'file-compression.high',
    [QuickSetsFileEnum.COMPRESSION_BLACK]: 'file-compression-black.auto',
    [QuickSetsFileEnum.COMPRESSION_COLOR]: 'file-compression-color.tiff-6',
    [QuickSetsFileEnum.PDF_ENCRYPTION]: 'false',
    [QuickSetsFileEnum.BLANK_PAGE_SUPPRESSION]: 'false',
    [FileNameEnum.PREFIX]: '',
    [FileNameEnum.SUFFIX]: '',
    [FileNameEnum.EDITABLE]: 'true',
    [FileNameEnum.NAME]: ''
  }
}

const QuickSetsControl = (props) => {
  const description: ArrayAttributeDescription = props.description
  const {
    compliance,
    attributes,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const { isEnabled, addDisabled, removeDisabled } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )

  const [showModal, setShowModal] = useState(QuickSetModalMode.NONE)
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [tableData, setTableData] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(NO_SELECTION)
  const [selectedCount, setSelectedCount] = useState(0)

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  useEffect(
    () => setTableData(value ? value.map((x, i) => tableItem(x, i)) : []),
    [value]
  )

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const schedules = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(schedules) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  useEffect(() => {
    const selected = tableData.filter((x) => x.rowConfig.selected).length
    setSelectedCount(selected)
    setRowSelectAllState(getRowSelectAllState(selected, tableData.length))
  }, [tableData])

  const setData = (newTableData) => {
    setTableData(newTableData)
    const settings = [...deviceSettings]
    const newValue = newTableData.map((x) => x.rowConfig.item)
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const onChange = (v, add) => {
    setData(
      add
        ? [...tableData, tableItem(v, tableData.length)]
        : [
            ...tableData.slice(0, selectedIndex),
            tableItem(v, selectedIndex),
            ...tableData.slice(selectedIndex + 1)
          ]
    )
  }

  const onAdd = (modalMode = QuickSetModalMode.ADD) => {
    setSelectedIndex(
      modalMode === QuickSetModalMode.ADD
        ? NO_SELECTION
        : tableData.findIndex((x) => x.rowConfig.selected)
    )
    setShowModal(modalMode)
  }

  const onRemove = () => setData(tableData.filter((x) => !x.rowConfig.selected))

  const tableItem = (item, i) => {
    const selected = i < tableData.length && !!tableData[i].rowConfig.selected
    return {
      [TABLE_CONSTANTS.ID]: i,
      [QuickSetsEnum.TITLE]: item[QuickSetsEnum.TITLE],
      [QuickSetsEnum.TYPE]: getLocalized('type-email'),
      [QuickSetsEnum.DESCRIPTION]: item[QuickSetsEnum.DESCRIPTION],
      rowConfig: { selected, item }
    }
  }

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const found = tableData.find(({ id }) => id === rowId)
    if (found) {
      found.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    setTableData(
      tableData.map((row) => {
        row.rowConfig.selected = checked
        return row
      })
    )
  }

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: TABLE_CONSTANTS.ID, label: TABLE_CONSTANTS.ID, index },
      { id: QuickSetsEnum.TITLE, label: getLocalized('title') },
      { id: QuickSetsEnum.TYPE, label: getLocalized('type') },
      { id: QuickSetsEnum.DESCRIPTION, label: getLocalized('description') }
    ]
  }, [])

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{text}</PreviewLabel>
    ) : (
      <TextBlack className={'marginBottom16'} disabled={!enabled}>
        {text}
      </TextBlack>
    )

  const enabled = isEnabled(attribute)
  const nonSingleSelection = !enabled || selectedCount !== 1
  return (
    <>
      <FlexColumn>
        {tableHeader(getLocalized('label'))}
        {onAttributeChange && (
          <FlexRow className={'marginBottom12 alignCenter'}>
            <ButtonGroup>
              <Button
                appearance={'secondary'}
                disabled={!enabled}
                onClick={() => onAdd()}
                id={'addQuickSet'}
              >
                {getLocalized('common.add')}
              </Button>
              <Button
                appearance={'secondary'}
                disabled={nonSingleSelection}
                onClick={() => onAdd(QuickSetModalMode.EDIT)}
                id={'editQuickSet'}
              >
                {getLocalized('common.edit')}
              </Button>
              <Button
                appearance={'secondary'}
                disabled={nonSingleSelection}
                onClick={() => onAdd(QuickSetModalMode.COPY)}
                id={'editQuickSet'}
              >
                {getLocalized('common.copy')}
              </Button>
              <Button
                appearance={'secondary'}
                disabled={!enabled || !selectedCount}
                onClick={onRemove}
                id={'removeQuickSet'}
              >
                {getLocalized('common.remove')}
              </Button>
            </ButtonGroup>
          </FlexRow>
        )}
        <Table
          rowSelectAllState={rowSelectAllState}
          rowSelector={
            onAttributeChange && enabled ? 'multiSelection' : undefined
          }
          columns={tableColumns}
          data={tableData}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          className={'widthColAuto'}
          data-testid={'id-quick-sets-table'}
          preferences={tablePreferences}
          i18n={Retrievei18nItems()}
        />
        {compliance && (
          <PreviewItem compliance={compliance} className={'marginTop4'} />
        )}
      </FlexColumn>
      {showModal !== QuickSetModalMode.NONE && (
        <QuickSetsModal
          edit={showModal === QuickSetModalMode.EDIT}
          constraints={description.constraints}
          value={
            selectedIndex === NO_SELECTION
              ? defaultQuickSetObject
              : tableData[selectedIndex].rowConfig.item
          }
          onClose={() => setShowModal(QuickSetModalMode.NONE)}
          getLocalized={getLocalized}
          onChange={(v) => onChange(v, showModal !== QuickSetModalMode.EDIT)}
        />
      )}
    </>
  )
}

export default memo(QuickSetsControl)
