import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Button, ButtonGroup, Table } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { ArrayAttributeDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import { FlexRow, FlexColumn, TextBlack, PreviewLabel } from 'styles/styles'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import HolidayScheduleModal, {
  HolidayScheduleEnum
} from './HolidayScheduleModal'
import {
  getRowSelectAllState,
  DATE_FORMAT,
  TABLE_CONSTANTS,
  NO_SELECTION,
  formatDate
} from 'common/utilities'
import 'styles/global.scss'

const tablePreferences = {
  width: [
    { columnId: HolidayScheduleEnum.NAME, width: 140 },
    { columnId: HolidayScheduleEnum.START, width: 180 },
    { columnId: HolidayScheduleEnum.END, width: 180 }
  ]
}

const defaultSchedule = () => {
  // Whole next day
  const start = new Date()
  start.setHours(start.getHours() + 1, 0, 0, 0)
  const end = new Date(start)
  end.setHours(end.getHours() + 24)
  return {
    [HolidayScheduleEnum.NAME]: '',
    [HolidayScheduleEnum.START]: start.toISOString(),
    [HolidayScheduleEnum.END]: end.toISOString()
  }
}

const HolidayScheduleControl = (props) => {
  const description: ArrayAttributeDescription = props.description
  const {
    compliance,
    attributes,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const { isEnabled, addDisabled, removeDisabled } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const [tableData, setTableData] = useState([])
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [selectedIndex, setSelectedIndex] = useState(NO_SELECTION)
  const [selectedCount, setSelectedCount] = useState(0)
  const [showModal, setShowModal] = useState(false)

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const holidays = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(holidays) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const onChange = (v) => {
    setData(
      selectedIndex === NO_SELECTION
        ? [...tableData, tableItem(v, tableData.length)]
        : [
            ...tableData.slice(0, selectedIndex),
            tableItem(v, selectedIndex),
            ...tableData.slice(selectedIndex + 1)
          ]
    )
  }

  const setData = (newTableData) => {
    setTableData(newTableData)
    const settings = [...deviceSettings]
    const newValue = newTableData.map((x) => x.rowConfig.item)
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const onAdd = (edit = false) => {
    setSelectedIndex(
      edit ? tableData.findIndex((x) => x.rowConfig.selected) : NO_SELECTION
    )
    setShowModal(true)
  }

  const onRemove = () => setData(tableData.filter((x) => !x.rowConfig.selected))

  const tableItem = (item, i) => {
    const selected = i < tableData.length && !!tableData[i].rowConfig.selected
    const getDate = (date) =>
      formatDate(new Date(date), DATE_FORMAT.LAST_MODIFIED)
    return {
      [TABLE_CONSTANTS.ID]: i,
      [HolidayScheduleEnum.NAME]: item[HolidayScheduleEnum.NAME],
      [HolidayScheduleEnum.START]: getDate(item[HolidayScheduleEnum.START]),
      [HolidayScheduleEnum.END]: getDate(item[HolidayScheduleEnum.END]),
      rowConfig: { selected, item }
    }
  }

  useEffect(
    () => setTableData(value ? value.map((x, i) => tableItem(x, i)) : []),
    [value]
  )

  useEffect(() => {
    const selected = tableData.filter((x) => x.rowConfig.selected).length
    setSelectedCount(selected)
    setRowSelectAllState(getRowSelectAllState(selected, tableData.length))
  }, [tableData])

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: TABLE_CONSTANTS.ID, label: TABLE_CONSTANTS.ID, index },
      { id: HolidayScheduleEnum.NAME, label: getLocalized('event-name') },
      { id: HolidayScheduleEnum.START, label: getLocalized('start') },
      { id: HolidayScheduleEnum.END, label: getLocalized('end') }
    ]
  }, [])

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const found = tableData.find(({ id }) => id === rowId)
    if (found) {
      found.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{text}</PreviewLabel>
    ) : (
      <TextBlack className={'marginBottom16'} disabled={!enabled}>
        {text}
      </TextBlack>
    )

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    setTableData(
      tableData.map((row) => {
        row.rowConfig.selected = checked
        return row
      })
    )
  }

  const enabled = isEnabled(attribute)
  return (
    <>
      <FlexColumn>
        {tableHeader(getLocalized('holiday-schedule'))}
        {onAttributeChange && (
          <FlexRow className={'marginBottom12 alignCenter'}>
            <ButtonGroup>
              <Button
                id={'addHolidaySchedule'}
                appearance={'secondary'}
                disabled={!enabled}
                onClick={() => onAdd()}
              >
                {getLocalized('common.add')}
              </Button>
              <Button
                id={'editHolidaySchedule'}
                appearance={'secondary'}
                disabled={!enabled || selectedCount !== 1}
                onClick={() => onAdd(true)}
              >
                {getLocalized('common.edit')}
              </Button>
              <Button
                id={'removeHolidaySchedule'}
                appearance={'secondary'}
                disabled={!enabled || !selectedCount}
                onClick={onRemove}
              >
                {getLocalized('common.remove')}
              </Button>
            </ButtonGroup>
            <TextBlack disabled={!enabled} className={'marginLeft12'}>
              {getLocalized('n-schedules-added', {
                count: tableData.length
              })}
            </TextBlack>
          </FlexRow>
        )}
        <Table
          rowSelectAllState={rowSelectAllState}
          className={'widthColAuto'}
          data-testid={'id-holiday-schedule-table'}
          preferences={tablePreferences}
          i18n={Retrievei18nItems()}
          columns={tableColumns}
          data={tableData}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          rowSelector={
            onAttributeChange && enabled ? 'multiSelection' : undefined
          }
        />
        {compliance && (
          <PreviewItem compliance={compliance} className={'marginTop4'} />
        )}
      </FlexColumn>
      {showModal && (
        <HolidayScheduleModal
          value={
            selectedIndex !== NO_SELECTION
              ? tableData[selectedIndex].rowConfig.item
              : defaultSchedule()
          }
          values={tableData
            .filter((_, index) => index !== selectedIndex)
            .map((x) => x.rowConfig.item)}
          edit={selectedIndex !== NO_SELECTION}
          onChange={(v) => onChange(v)}
          onClose={() => setShowModal(false)}
          getLocalized={getLocalized}
        />
      )}
    </>
  )
}

export default memo(HolidayScheduleControl)
