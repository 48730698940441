import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import configContext from 'context/config/configContext'
import settingsContext from 'context/settings/settingsContext'
import { IconArrowUp, IconArrowDown, Button, Card } from '@veneer/core'
import { PriorityListControlDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import { FlexColumn, FlexRow, TextBlack } from 'styles/styles'
import HelpButton from 'components/policies/settings/attributes/HelpButton'
import 'styles/global.scss'
import './index.scss'

const PriorityListControl = (props) => {
  const description: PriorityListControlDescription = props.description

  const NO_SELECTION = -1

  const [selectedIndex, setSelectedIndex] = useState(NO_SELECTION)

  const defaultValue: string[] = description.items.map((item) => item.value)

  const { t } = useContext(configContext)
  const { isEnabled, addDisabled, removeDisabled } = useContext(settingsContext)

  const handleRowSelect = (rowId) => {
    setSelectedIndex(selectedIndex !== rowId ? rowId : NO_SELECTION)
  }

  const getLocalized = (key: string): string => {
    return t(`${props.localizationPath}.${key}`)
  }

  const enabled = isEnabled(description.attribute)
  const [value, setValue] = useState(
    PoliciesHelper.getData(description, props.data.deviceSettings)
  )

  useEffect(() => {
    if (!enabled) {
      setSelectedIndex(NO_SELECTION)
    }
  }, [enabled])

  const pagedData = useMemo(() => {
    const mappedTableData = []
    value.forEach((item) => {
      const found = description.items.find((x) => x.value === item)
      if (found) {
        mappedTableData.push(found)
      }
    })
    return mappedTableData
  }, [value])

  // If data & items are not consistent - update data accordingly
  useEffect(() => {
    if (description.items.length !== value.length) {
      // might happen once on loading the component
      setValueEx(defaultValue, selectedIndex)
    }
  }, [value])

  useEffect(() => {
    setValue(PoliciesHelper.getData(description, props.data.deviceSettings))
  }, [props.data.deviceSettings])

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const val = PoliciesHelper.getData(description, props.data.deviceSettings)
    if (props.attributes && val.toString() === value.toString()) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        props.attributes
      )
    }
  }, [value, props.attributes])

  const setValueEx = (newValue, newIndex) => {
    const deviceSettings = [...props.data.deviceSettings]
    PoliciesHelper.setData(description, deviceSettings, newValue)
    props.onAttributeChange({ ...props.data, deviceSettings })

    setValue(newValue)
    setSelectedIndex(newIndex)
  }

  const moveElement = (newIndex) => {
    const newValue = [...value]
    const movedElement = newValue.splice(selectedIndex, 1)[0]
    newValue.splice(newIndex, 0, movedElement)
    setValueEx(newValue, newIndex)
  }

  const handleArrowUp = (doIt = false) => {
    if (
      selectedIndex !== NO_SELECTION &&
      selectedIndex > 0 &&
      !pagedData[selectedIndex - 1].fixed
    ) {
      if (doIt) {
        moveElement(selectedIndex - 1)
      }
      return true
    }
    return false
  }

  const handleArrowDown = (doIt = false) => {
    if (
      selectedIndex !== NO_SELECTION &&
      selectedIndex < pagedData.length - 1 &&
      !pagedData[selectedIndex + 1].fixed
    ) {
      if (doIt) {
        moveElement(selectedIndex + 1)
      }
      return true
    }
    return false
  }

  const resetList = () => {
    setValueEx(defaultValue, NO_SELECTION)
  }

  const disableReset =
    defaultValue.length === value.length &&
    defaultValue.every((element, index) => element === value[index])

  const disabledHelp = !enabled && !!description.disabledHelp
  return (
    <>
      <FlexRow className={'alignCenter marginBottom16'}>
        <TextBlack>{getLocalized(description.label)}</TextBlack>
        <FlexRow className={'paddingLeft4 lineBreak'}>
          {(description.help || disabledHelp) && (
            <HelpButton
              enabled={enabled || disabledHelp}
              description={getLocalized(
                disabledHelp ? description.disabledHelp : description.help
              )}
            />
          )}
        </FlexRow>
      </FlexRow>
      <FlexRow>
        <Card
          className={'change-list-priority-elements'}
          border={'outlined'}
          content={
            <FlexColumn id={'changePolicy'}>
              {pagedData.map((pd, i) => (
                <div
                  className={`change-list-priority-element${
                    selectedIndex === i ? ' colorHpBg' : ''
                  }`}
                  key={i}
                  onClick={() =>
                    enabled && !pagedData[i].fixed && handleRowSelect(i)
                  }
                >
                  <div>{getLocalized(pd.label)}</div>
                </div>
              ))}
            </FlexColumn>
          }
        />

        <FlexColumn className={'marginLeft8'}>
          <Button
            appearance={'ghost'}
            leadingIcon={<IconArrowUp />}
            onClick={() => handleArrowUp(true)}
            disabled={!enabled || !handleArrowUp()}
          />
          <Button
            appearance={'ghost'}
            leadingIcon={<IconArrowDown />}
            onClick={() => handleArrowDown(true)}
            disabled={!enabled || !handleArrowDown()}
          />
        </FlexColumn>
      </FlexRow>

      <Button
        onClick={resetList}
        appearance={'ghost'}
        disabled={!enabled || disableReset}
      >
        {getLocalized('reset-option')}
      </Button>
    </>
  )
}

export default memo(PriorityListControl)
