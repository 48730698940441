import React, { Fragment, memo } from 'react'

const DeviceAttributes = ({ deviceSettingForms }) => {
  return (
    <>
      {deviceSettingForms.map((setting) => (
        <Fragment key={setting.key}>
          <div className={setting.className}>{setting.form}</div>
        </Fragment>
      ))}
    </>
  )
}

export default memo(DeviceAttributes)
