import React, { useContext, useState, useMemo } from 'react'
import { Button, Select } from '@veneer/core'
import configContext from 'context/config/configContext'
import { FlexRow } from 'styles/styles'
import 'styles/global.scss'
import '../footer/index.scss'

export enum MenuItems {
  CLONE = 'clone',
  EDIT = 'edit',
  REMOVE = 'remove',
  EXPORT = 'export'
}

const TableFooter = (props) => {
  const {
    allowDelete,
    allowWrite,
    allowCreate,
    allowExport,
    disableDelete,
    numberOfSelectedItems,
    isWex
  } = props
  const { t } = useContext(configContext)
  const [selectedAction, setSelectedAction] = useState([])

  const key = (subkey: string): string => {
    return `policy.details.footer.${subkey}`
  }

  const handleSelectChange = (value) => {
    setSelectedAction(value)
  }

  const menuItems = useMemo(() => {
    const items = []
    if (numberOfSelectedItems) {
      if (numberOfSelectedItems === 1) {
        if (allowCreate) {
          items.push({
            value: MenuItems.CLONE,
            label: t('common.clone')
          })
        }
        if (allowWrite) {
          items.push({
            value: MenuItems.EDIT,
            label: t('common.edit')
          })
        }
      }
      if (allowExport) {
        items.push({
          value: MenuItems.EXPORT,
          label: t('common.export')
        })
      }
      if (allowDelete) {
        items.push({
          value: MenuItems.REMOVE,
          label: t('common.remove'),
          disabled: disableDelete
        })
      }
      // if current selection is not available - reset it
      if (
        selectedAction.length &&
        !items.find((x) => x.value === selectedAction[0] && !x.disabled)
      ) {
        handleSelectChange([])
      }
    }
    return items
  }, [
    allowDelete,
    allowWrite,
    allowCreate,
    disableDelete,
    numberOfSelectedItems
  ])

  return (
    <div>
      <div
        id={'policy-footer'}
        className={`policy-fixed ${isWex ? 'colorHpBgWx' : 'colorHpBg'}`}
      >
        <div className={'policy-table-leftBox'}>
          <Button
            id={'cancelButton'}
            appearance={'secondary'}
            onClick={() => props.onCancel()}
          >
            {t('common.cancel')}
          </Button>
          <FlexRow className={'marginLeft12 alignCenter'}>
            {t('common.n-items-selected', { count: numberOfSelectedItems })}
          </FlexRow>
        </div>
        <div className={'policy-buttons-fixed'}>
          <FlexRow>
            <Select
              placeholder={t('policy.table.selectAction')}
              options={menuItems}
              placement={'top-end'}
              value={selectedAction}
              clearIcon={false}
              onChange={({ value: v }) => handleSelectChange([v])}
              className={'select-action'}
            />
          </FlexRow>
          <FlexRow className={'marginLeft12'}>
            <Button
              id={'continueButton'}
              onClick={() => props.onContinue(selectedAction[0])}
              disabled={selectedAction.length === 0}
            >
              {t(key('continue'))}
            </Button>
          </FlexRow>
        </div>
      </div>
    </div>
  )
}

export default TableFooter
