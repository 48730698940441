import { IconWarningAlt, IconMinusCircle, IconInfo } from '@veneer/core'
import React from 'react'
import { FlexRow } from 'styles/styles'
import './index.scss'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

const WarningMessage = (props) => {
  const doNotShrink = { flexShrink: 0 }
  const renderIcon = () => {
    switch (props.type) {
      case MessageTypesEnum.ERROR:
        return (
          <IconMinusCircle
            customStyle={doNotShrink}
            size={20}
            filled
            color={'red7'}
          />
        )
      case MessageTypesEnum.INFO:
        return (
          <IconInfo
            customStyle={doNotShrink}
            size={20}
            filled
            color={'gray7'}
          />
        )
      default:
        return (
          <IconWarningAlt
            customStyle={doNotShrink}
            size={24}
            filled
            color={'darkOrange5'}
          />
        )
    }
  }

  return (
    <FlexRow className={'marginTop4 alignCenter'}>
      {renderIcon()}
      <div className={'warningText marginLeft4'} id={props.id}>
        {props.message}
      </div>
    </FlexRow>
  )
}

export default WarningMessage
