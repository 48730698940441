import React, { useContext } from 'react'
import uuid from 'react-uuid'

import { Button, ButtonGroup, IconWarning, Modal } from '@veneer/core'
import configContext from 'context/config/configContext'
import { FlexRow } from 'styles/styles'
import 'styles/global.scss'

const CreatePolicySuccess = (props) => {
  const { t } = useContext(configContext)

  const key = (subkey: string): string => {
    return t(`policy.create.${subkey}`)
  }

  return (
    props.show && (
      <Modal
        onClose={props.onDone}
        closeOnBlur={false}
        show={props.show}
        className={'small-policy-modal'}
        data-testid={'id-create-policy-success-modal'}
        footer={
          <ButtonGroup>
            <Button id={'idDone'} onClick={props.onDone}>
              {key('confirmModal.done')}
            </Button>
          </ButtonGroup>
        }
      >
        <FlexRow className={'marginBottom20 alignCenter'}>
          <IconWarning filled color={'hpBlue7'} size={36} key={uuid()} />
          <h4
            className={'marginLeft8'}
            data-testid={'id-create-policy-success-modal-title'}
          >
            {key('confirmModal.title')}
          </h4>
        </FlexRow>
        <p
          className={'paddingBottom16 lineHeight20'}
          data-testid={'id-create-policy-success-modal-text-1'}
        >
          <span className={'paddingRight16 noWrap'}>
            {key('confirmModal.policyName')}
          </span>
          <span
            className={' wrapAnywhere'}
            data-testid={'id-create-policy-success-modal-name'}
          >
            {props.policyName}
          </span>
        </p>
        <p data-testid={'id-create-policy-success-modal-text-2'}>
          {key('confirmModal.description')}
        </p>
      </Modal>
    )
  )
}

export default CreatePolicySuccess
