import React, {
  useContext,
  memo,
  useState,
  useEffect,
  useMemo,
  useRef
} from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import policiesContext from 'context/policies/policiesContext'
import { Button, ButtonGroup, Table } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { FlexRow, FlexColumn, TextBlack, PreviewLabel } from 'styles/styles'
import 'styles/global.scss'
import { ArrayAttributeDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import HelpButton from '../../HelpButton'
import WarningMessage from './WarningMessage'
import { getRowSelectAllState, generateBase64Value } from 'common/utilities'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

export enum WallpaperUploadEnum {
  NAME = 'name',
  ID = 'id',
  METADATA = 'metadata',
  WIDTH = 'width',
  HEIGHT = 'height',
  TYPE = 'type'
}

const WallpaperUploadControl = (props) => {
  const description: ArrayAttributeDescription = props.description
  const {
    compliance,
    attributes,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const { showError, fleetMgtSvcClient } = useContext(policiesContext)
  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    addError,
    removeError,
    displayAllErrors
  } = useContext(settingsContext)
  const fileInputRef = useRef(null)
  const [enabled, setEnabled] = useState(isEnabled(attribute))
  const [selectedCount, setSelectedCount] = useState(0)
  const [tableData, setTableData] = useState([])
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const showErrorMsg = displayError || displayAllErrors

  useEffect(() => {
    const err = enabled && !value.length
    setError(showErrorMsg && err)
    err
      ? addError(props.id, attribute, showErrorMsg)
      : removeError(props.id, attribute)
  }, [enabled, !value.length, showErrorMsg])

  useEffect(() => {
    setEnabled(isEnabled(attribute))
  }, [isEnabled(attribute)])

  useEffect(() => {
    setValue(PoliciesHelper.getData(description, deviceSettings))
  }, [deviceSettings])

  useEffect(() => {
    setTableData(value ? value.map((x) => tableItem(x)) : [])
  }, [value])

  useEffect(() => {
    const selected = tableData.filter((row) => row.rowConfig.selected).length
    setSelectedCount(selected)
    setRowSelectAllState(getRowSelectAllState(selected, tableData.length))
  }, [tableData])

  useEffect(() => {
    const wallpaperUpload = PoliciesHelper.getData(description, deviceSettings)
    if (
      attributes &&
      JSON.stringify(wallpaperUpload) === JSON.stringify(value)
    ) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const handleAddWallpaper = () => fileInputRef.current.click()

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files
    if (!selectedFiles || selectedFiles.length === 0) return

    const loadAndRecordPromises = []
    for (const selectedFile of selectedFiles) {
      const promise = new Promise((resolve) => {
        const img = new FileReader()
        img.onload = async function (image) {
          try {
            const res = await fleetMgtSvcClient.uploadFile(
              selectedFile.name,
              'IMAGE',
              generateBase64Value(image)
            )
            if (res.status && res.status === 200) {
              const newRecord = {
                [WallpaperUploadEnum.ID]: res.data.id,
                [WallpaperUploadEnum.NAME]: res.data.filename,
                [WallpaperUploadEnum.METADATA]: {
                  [WallpaperUploadEnum.WIDTH]: res.data.metadata.width,
                  [WallpaperUploadEnum.HEIGHT]: res.data.metadata.height,
                  [WallpaperUploadEnum.TYPE]: res.data.metadata.type
                }
              }
              const recordArranged = tableItem(newRecord)
              resolve(recordArranged)
            }
          } catch (error) {
            showError(error)
          }
        }
        img.readAsDataURL(selectedFile)
      })
      loadAndRecordPromises.push(promise)
    }
    Promise.all(loadAndRecordPromises).then((records) => {
      setData([...tableData, ...records])
    })
    fileInputRef.current.value = ''
  }

  const setData = (newTableData) => {
    setTableData(newTableData)
    setDisplayError(true)
    const settings = [...deviceSettings]
    const newValue = newTableData.map((row) => row.rowConfig.item)
    setValue(newValue)
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const onRemove = () => {
    const data = tableData.filter((rowRecord) => !rowRecord.rowConfig.selected)
    setData(data)
  }

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const rowRecord = tableData.find(({ id }) => id === rowId)
    if (rowRecord) {
      rowRecord.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    setTableData(
      tableData.map((row) => {
        row.rowConfig.selected = checked
        return row
      })
    )
  }

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{text}</PreviewLabel>
    ) : (
      <FlexRow className={'alignCenter marginBottom16'}>
        <TextBlack disabled={!enabled}>{text}</TextBlack>
        <FlexRow className={'paddingLeft4'}>
          <HelpButton enabled description={generateTooltipDescription()} />
        </FlexRow>
      </FlexRow>
    )

  const generateTooltipDescription = () => {
    return (
      <>
        {getLocalized('wallpaper-tooltip-descr')}
        <br />
        <br />
        <ul>
          <li>{getLocalized('wallpaper-tooltip-2')}</li>
          <li>{getLocalized('wallpaper-tooltip-4')}</li>
          <li>{getLocalized('wallpaper-tooltip-8')}</li>
          <li>{getLocalized('wallpaper-tooltip-10')}</li>
        </ul>
      </>
    )
  }

  const tableItem = (item) => {
    const rowId = item[WallpaperUploadEnum.ID]
    const selected = tableData.some(
      (record) =>
        record[WallpaperUploadEnum.ID] === rowId && record.rowConfig.selected
    )
    const metadata = item[WallpaperUploadEnum.METADATA]
    return {
      [WallpaperUploadEnum.ID]: item[WallpaperUploadEnum.ID],
      [WallpaperUploadEnum.NAME]: item[WallpaperUploadEnum.NAME],
      [WallpaperUploadEnum.WIDTH]: metadata[WallpaperUploadEnum.WIDTH],
      [WallpaperUploadEnum.HEIGHT]: metadata[WallpaperUploadEnum.HEIGHT],
      [WallpaperUploadEnum.TYPE]: metadata[WallpaperUploadEnum.TYPE],
      rowConfig: { item, selected }
    }
  }

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: WallpaperUploadEnum.ID, label: WallpaperUploadEnum.ID, index },
      {
        id: WallpaperUploadEnum.NAME,
        label: getLocalized('name')
      },
      {
        id: WallpaperUploadEnum.WIDTH,
        label: getLocalized('width')
      },
      {
        id: WallpaperUploadEnum.HEIGHT,
        label: getLocalized('height')
      },
      {
        id: WallpaperUploadEnum.TYPE,
        label: getLocalized('type')
      }
    ]
  }, [])

  return (
    <>
      <FlexColumn>
        {tableHeader(getLocalized('wallpaper-file'))}
        {onAttributeChange && (
          <FlexRow className={'marginBottom12 alignCenter'}>
            <ButtonGroup>
              <input
                type={'file'}
                ref={fileInputRef}
                onChange={handleFileChange}
                className={'displayNone'}
                accept={'.bmp,.gif,.jpg,.jpeg,.png'}
                multiple
              />
              <Button
                onClick={handleAddWallpaper}
                appearance={'secondary'}
                disabled={!enabled}
                id={'addWallpaper'}
              >
                {getLocalized('common.add')}
              </Button>
              <Button
                onClick={onRemove}
                appearance={'secondary'}
                disabled={!enabled || selectedCount < 1}
                id={'removeWallpaper'}
              >
                {getLocalized('common.remove')}
              </Button>
            </ButtonGroup>
            <TextBlack className={'marginLeft12'} disabled={!enabled}>
              {getLocalized('common.n-files-added', {
                count: tableData.length
              })}
            </TextBlack>
          </FlexRow>
        )}
        <Table
          columns={tableColumns}
          data={tableData}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          rowSelectAllState={rowSelectAllState}
          rowSelector={
            onAttributeChange && enabled ? 'multiSelection' : undefined
          }
          preferences={{
            width: [
              { columnId: WallpaperUploadEnum.NAME, width: 200 },
              { columnId: WallpaperUploadEnum.WIDTH, width: 80 },
              { columnId: WallpaperUploadEnum.HEIGHT, width: 80 },
              { columnId: WallpaperUploadEnum.TYPE, width: 100 }
            ]
          }}
          className={'widthColAuto'}
          i18n={Retrievei18nItems()}
        />
        {error && (
          <WarningMessage
            id={attribute + '.error'}
            type={MessageTypesEnum.ERROR}
            message={getLocalized('common.errors.not-selected')}
          />
        )}
        {compliance && (
          <PreviewItem compliance={compliance} className={'marginTop4'} />
        )}
      </FlexColumn>
    </>
  )
}

export default memo(WallpaperUploadControl)
