import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Item } from '../PoliciesConfiguration'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

export default class CopyColorItems {
  static modes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Copy_Color_Mode}.mode.auto`,
      label: 'mode-auto',
      option: 'autoDetect'
    },
    {
      value: `${PolicyAttributeEnum.Copy_Color_Mode}.mode.color`,
      label: 'mode-color',
      option: 'color'
    },
    {
      value: `${PolicyAttributeEnum.Copy_Color_Mode}.mode.black`,
      label: 'mode-black',
      option: 'grayscale'
    }
  ]

  static copyColorModes = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? CopyColorItems.modes
      : constraints.filterSettingOptions(
          CopyColorItems.modes,
          ConstraintsResourceEnum.jobTicketCfgDefCopy,
          ConstraintsSettingEnum.srcScanColorMode
        )
}
