import React, { useContext, useEffect, useState, memo, useMemo } from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { Table, Button } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { ObjectAttributeDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import EmailAddressItems from 'context/policies/dropboxItems/EmailAddressItems'
import { FlexColumn, TextBlack, PreviewLabel } from 'styles/styles'
import EmailAddressMessageModal, {
  requiredIds
} from './EmailAddressMessageModal'
import { EmailMessageEnum } from './EmailMessageEnums'
import 'styles/global.scss'
import WarningMessage from '../WarningMessage'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import { TABLE_CONSTANTS, isTrueString } from 'common/utilities'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'

const columnId = 'id'
const columnOption = 'option'
const columnValue = 'value'
const columnEditable = 'editable'

const tablePreferences = {
  width: [
    { columnId: columnOption, width: 180 },
    { columnId: columnValue, width: 240 },
    { columnId: columnEditable, width: 120 }
  ]
}

const EmailMessageControl = (props) => {
  const description: ObjectAttributeDescription = props.description
  const {
    attributes,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute, constraints } = description
  const { tt } = useContext(configContext)
  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    addError,
    removeError,
    displayAllErrors
  } = useContext(settingsContext)

  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const enabled = isEnabled(attribute)
  const [value, setValue] = useState(
    PoliciesHelper.getData(description, deviceSettings)
  )
  const [showModal, setShowModal] = useState(false)
  const [tableData, setTableData] = useState([])

  useEffect(
    () => setValue(PoliciesHelper.getData(description, deviceSettings)),
    [deviceSettings]
  )

  const compliance = useMemo(
    () => PoliciesHelper.getCompliance(description, deviceSettings),
    [deviceSettings]
  )

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const valEmail = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && JSON.stringify(valEmail) === JSON.stringify(value)) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const getTableData = (val) => {
    const booleanKey = (v) => (isTrueString(v) ? 'common.on' : 'common.off')
    const cell = (text, status) => (
      <>
        {text}
        {status && <PreviewItem compliance={status} />}
      </>
    )
    const itemDropdown = (id: EmailMessageEnum, v) => {
      const found = EmailAddressItems.recipientItems.find(
        (x) => x.value === v[id]
      )
      const editable = v[id + EmailMessageEnum.EDITABLE]
      return {
        [columnId]: id,
        [columnOption]: getLocalized(id),
        [columnValue]: cell(
          found ? getLocalized(found.label) : TABLE_CONSTANTS.NO_DATA,
          compliance[id]
        ),
        [columnEditable]: editable
          ? cell(
              getLocalized(booleanKey(editable)),
              compliance[id + EmailMessageEnum.EDITABLE]
            )
          : TABLE_CONSTANTS.NO_DATA
      }
    }
    const itemTextbox = (id, v, editableCheck = false) => {
      const editable = editableCheck ? v[id + EmailMessageEnum.EDITABLE] : null
      return {
        [columnId]: id,
        [columnOption]: getLocalized(id),
        [columnValue]: cell(v[id], compliance[id]),
        [columnEditable]: editable
          ? cell(
              getLocalized(booleanKey(editable)),
              compliance[id + EmailMessageEnum.EDITABLE]
            )
          : TABLE_CONSTANTS.NO_DATA
      }
    }
    const ccBcc = []
    if (EmailMessageEnum.CC in val) {
      ccBcc.push(itemDropdown(EmailMessageEnum.CC, val))
    }
    if (EmailMessageEnum.BCC in val) {
      ccBcc.push(itemDropdown(EmailMessageEnum.BCC, val))
    }
    return [
      itemDropdown(EmailMessageEnum.FROM, val),
      itemTextbox(EmailMessageEnum.DEF_FROM, val),
      itemTextbox(EmailMessageEnum.DEF_NAME, val),
      itemDropdown(EmailMessageEnum.TO, val),
      ...ccBcc,
      itemTextbox(EmailMessageEnum.SUBJECT, val, true),
      itemTextbox(EmailMessageEnum.MESSAGE, val, true)
    ]
  }

  useEffect(() => setTableData(getTableData(value)), [value])

  const showError = displayError || displayAllErrors

  const checkConstraints = (x) =>
    constraints
      ? !!constraints.isPolicyAttributeSettingSupported(
          PolicyAttributeEnum.Email_Message,
          PolicyAttributeEnum.Email_Message + EmailMessageEnum.OBJECT + x
        )
      : true

  const hasErrors = () => {
    return requiredIds
      .slice(
        0,
        value[EmailMessageEnum.FROM] !== EmailAddressItems.fromItems[0].value
          ? -4
          : -2
      )
      .filter((id) => checkConstraints(id))
      .some((id) => !value[id])
  }

  useEffect(() => {
    const err = enabled && hasErrors()
    setError(showError && err)
    err
      ? addError(props.id, attribute, showError)
      : removeError(props.id, attribute)
  }, [enabled, value, showError])

  const setData = (newValue) => {
    setDisplayError(true)
    setTableData(getTableData(newValue))
    setValue(newValue)
    const settings = [...deviceSettings]
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{text}</PreviewLabel>
    ) : (
      <TextBlack className={'marginBottom16'} disabled={!enabled}>
        {text}
      </TextBlack>
    )

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: columnId, label: columnId, index },
      { id: columnOption, label: getLocalized('option-name') },
      { id: columnValue, label: getLocalized('value') },
      { id: columnEditable, label: getLocalized('user-editable') }
    ]
  }, [])

  return (
    <>
      <FlexColumn>
        {tableHeader(getLocalized('label'))}
        {onAttributeChange && (
          <div className={'marginBottom12'}>
            <Button
              appearance={'secondary'}
              disabled={!enabled}
              onClick={() => setShowModal(true)}
              id={'setEmailMesssage'}
            >
              {getLocalized('title')}
            </Button>
          </div>
        )}
        <Table
          columns={tableColumns}
          data={tableData}
          className={'widthColAuto'}
          data-testid={'id-email-message-table'}
          preferences={tablePreferences}
          i18n={Retrievei18nItems()}
        />
        {error && (
          <WarningMessage
            id={attribute + '.error'}
            type={MessageTypesEnum.ERROR}
            message={getLocalized('common.errors.not-selected')}
          />
        )}
      </FlexColumn>
      {showModal && (
        <EmailAddressMessageModal
          constraints={constraints}
          localizationPath={props.localizationPath}
          value={value}
          checkConstraints={checkConstraints}
          onClose={() => {
            setShowModal(false)
          }}
          onChange={setData}
        />
      )}
    </>
  )
}

export default memo(EmailMessageControl)
