import React from 'react'
import styled from 'styled-components'
import primitives from '@veneer/primitives'

const Divider = styled.hr`
  border-width: 0;
  border-left: 3px solid ${primitives.color.gray3};
  margin: 0 8px;
`
const VerticalDivider = () => <Divider />

export default VerticalDivider
