import React from 'react'
import { Card, Checkbox, Scrollbar } from '@veneer/core'
import { FlexColumn, cssFullHeight } from 'styles/styles'
import FileName, {
  getFileNamePreview
} from 'components/policies/modal/FileName'
import FileSettingItems from 'context/policies/dropboxItems/FileSettingItems'
import PrintMediaItems from 'context/policies/dropboxItems/PrintMediaItems'
import LabelSelect from 'common/controls/labelSelect'
import { QuickSetsFileEnum } from './QuickSetsEnums'
import { errorKeys } from './QuickSetsModal'
import { isTrueString } from 'common/utilities'

const AddQuickSetStep3 = (props) => {
  const { getLocalized, value, onChange, requiredErrors, setRequiredErrors } =
    props

  const getLocalizedEx = (key) =>
    getLocalized(`policy.settings.attributes.email-file.device_settings.${key}`)

  const getDropdownItems = (items) =>
    items.map((item) => ({ ...item, label: getLocalized(item.label) }))

  const inputFieldsData = {
    [QuickSetsFileEnum.MEDIA_SIZE]: {
      options: getDropdownItems(PrintMediaItems.mediaSizes()),
      value: value[QuickSetsFileEnum.MEDIA_SIZE]
    },
    [QuickSetsFileEnum.COLOR_PREFERENCE]: {
      options: getDropdownItems(FileSettingItems.fileColorPrefItems),
      value: value[QuickSetsFileEnum.COLOR_PREFERENCE]
    },
    [QuickSetsFileEnum.QUALITY]: {
      options: getDropdownItems(FileSettingItems.fileQualityItems),
      value: value[QuickSetsFileEnum.QUALITY]
    },
    [QuickSetsFileEnum.TYPE]: {
      options: getDropdownItems(FileSettingItems.fileTypeItems),
      value: value[QuickSetsFileEnum.TYPE]
    },
    [QuickSetsFileEnum.RESOLUTION]: {
      options: getDropdownItems(FileSettingItems.fileResolutionItems),
      value: value[QuickSetsFileEnum.RESOLUTION]
    },
    [QuickSetsFileEnum.COMPRESSION]: {
      options: getDropdownItems(FileSettingItems.fileCompressionItems),
      value: value[QuickSetsFileEnum.COMPRESSION]
    },
    [QuickSetsFileEnum.COMPRESSION_BLACK]: {
      options: getDropdownItems(FileSettingItems.fileCompressionBlackItems),
      value: value[QuickSetsFileEnum.COMPRESSION_BLACK]
    },
    [QuickSetsFileEnum.COMPRESSION_COLOR]: {
      options: getDropdownItems(FileSettingItems.fileCompressionColorItems),
      value: value[QuickSetsFileEnum.COMPRESSION_COLOR]
    }
  }

  const generateCheckbox = (id: string, isEnabled = () => true) => (
    <Checkbox
      id={id}
      label={getLocalizedEx(id)}
      key={id}
      disabled={!isEnabled()}
      checked={isTrueString(value[id])}
      onChange={(check) => {
        const { checked, id } = check.target
        onChange({ [id]: checked + '' })
      }}
    />
  )

  const generateSelect = (
    id: string,
    isEnabled = () => true,
    changedData = (x) => x
  ) => {
    const error = requiredErrors[id]
    const selectedBoxData = inputFieldsData[id]
    return (
      <LabelSelect
        id={id}
        label={getLocalizedEx(id)}
        className={'marginBottom16 maxTextWidth'}
        placeholder={getLocalized('common.select-option')}
        options={selectedBoxData.options}
        value={[selectedBoxData.value]}
        disabled={!isEnabled()}
        error={!!error}
        helperText={error?.message ? getLocalized(error?.message) : null}
        onChange={(option) => {
          setRequiredErrors((prevValue) => {
            const newValue = { ...prevValue }
            if (!option.value) {
              newValue[id] = { message: errorKeys[0] }
            } else {
              delete newValue[id]
            }
            return newValue
          })
          onChange(changedData({ [id]: option.value }))
        }}
      />
    )
  }

  const generateCardContent = () => {
    const id = QuickSetsFileEnum.FILE_NAME
    const error = requiredErrors[id]
    return (
      <Scrollbar customStyle={cssFullHeight}>
        <FlexColumn className={'padding1224'}>
          <FileName
            simple={false}
            value={value}
            fileType={value[QuickSetsFileEnum.TYPE]}
            error={!!error}
            attribute={'quick-set'}
            onChange={(fileName) => {
              setRequiredErrors((prevValue) => {
                const newValue = { ...prevValue }
                if (!getFileNamePreview(fileName).length) {
                  newValue[id] = { message: errorKeys[0] }
                } else {
                  delete newValue[id]
                }
                return newValue
              })
              onChange(fileName)
            }}
          />
          <div className={'marginBottom16'} />
          {generateSelect(QuickSetsFileEnum.MEDIA_SIZE)}
          {generateSelect(QuickSetsFileEnum.COLOR_PREFERENCE)}
          {generateSelect(QuickSetsFileEnum.QUALITY)}
          {generateSelect(QuickSetsFileEnum.RESOLUTION)}
          {generateSelect(
            QuickSetsFileEnum.TYPE,
            () => true,
            (x) =>
              FileSettingItems.isFileTypePdf(x[QuickSetsFileEnum.TYPE])
                ? x
                : { ...x, [QuickSetsFileEnum.PDF_ENCRYPTION]: 'false' }
          )}
          <div className={'marginBottom16 devSettingsIndent1'}>
            {generateSelect(QuickSetsFileEnum.COMPRESSION_BLACK, () =>
              FileSettingItems.isFileTypeTiff(value[QuickSetsFileEnum.TYPE])
            )}
            {generateSelect(QuickSetsFileEnum.COMPRESSION_COLOR, () =>
              FileSettingItems.isFileTypeTiff(value[QuickSetsFileEnum.TYPE])
            )}
            {generateSelect(QuickSetsFileEnum.COMPRESSION, () =>
              FileSettingItems.isFileTypeCompressed(
                value[QuickSetsFileEnum.TYPE]
              )
            )}
            {generateCheckbox(QuickSetsFileEnum.PDF_ENCRYPTION, () =>
              FileSettingItems.isFileTypePdf(value[QuickSetsFileEnum.TYPE])
            )}
          </div>
          {generateCheckbox(QuickSetsFileEnum.BLANK_PAGE_SUPPRESSION)}
        </FlexColumn>
      </Scrollbar>
    )
  }
  return (
    <>
      <h5 className={'step-heading'}>{getLocalized('header', 3)}</h5>
      <p className={'contentPadding'}>{getLocalized('step-description', 3)}</p>
      <div className={'quick-set-card-height'}>
        <Card
          id={'add-quick-set-step-3'}
          content={generateCardContent()}
          border={'dropShadow'}
          customStyle={{ height: '100%', padding: '12px 0 12px 0' }}
        />
      </div>
    </>
  )
}

export default AddQuickSetStep3
