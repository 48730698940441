import { Item } from '../PoliciesConfiguration'

export default class HomeScreenLanguageItems {
  static keyboardLayouts: Item[] = [
    { value: 'kb-layout.ar', label: 'kb-layout-ar' },
    { value: 'kb-layout.be-comma', label: 'kb-layout-be-comma' },
    { value: 'kb-layout.nl-be', label: 'kb-layout-nl-be' },
    { value: 'kb-layout.bg', label: 'kb-layout-bg' },
    // { value: 'kb-layout.bg-latn', label: 'kb-layout-bg-latn' }, //no CDM value
    // { value: 'kb-layout.en-ca', label: 'kb-layout-en-ca' }, //no CDM value
    { value: 'kb-layout.ca-es', label: 'kb-layout-ca-es' },
    { value: 'kb-layout.zh-tw', label: 'kb-layout-zh-tw' },
    { value: 'kb-layout.zh-cn', label: 'kb-layout-zh-cn' },
    { value: 'kb-layout.hr', label: 'kb-layout-hr' },
    { value: 'kb-layout.cs', label: 'kb-layout-cs' },
    { value: 'kb-layout.cs-qwerty', label: 'kb-layout-cs-qwerty' },
    { value: 'kb-layout.da', label: 'kb-layout-da' },
    { value: 'kb-layout.nl', label: 'kb-layout-nl' },
    { value: 'kb-layout.en-uk', label: 'kb-layout-en-uk' },
    { value: 'kb-layout.en-us', label: 'kb-layout-en-us' },
    { value: 'kb-layout.et', label: 'kb-layout-et' },
    { value: 'kb-layout.fi', label: 'kb-layout-fi' },
    { value: 'kb-layout.fr-be', label: 'kb-layout-fr-be' },
    { value: 'kb-layout.fr-ca', label: 'kb-layout-fr-ca' },
    // { value: 'kb-layout.fr-ca-legacy', label: 'kb-layout-fr-ca-legacy' }, //no CDM value
    { value: 'kb-layout.fr-fr', label: 'kb-layout-fr-fr' },
    { value: 'kb-layout.fr-ch', label: 'kb-layout-fr-ch' },
    { value: 'kb-layout.de', label: 'kb-layout-de' },
    { value: 'kb-layout.de-ch', label: 'kb-layout-de-ch' },
    { value: 'kb-layout.el', label: 'kb-layout-el' },
    { value: 'kb-layout.he', label: 'kb-layout-he' },
    { value: 'kb-layout.hu-qwertz', label: 'kb-layout-hu-qwertz' },
    { value: 'kb-layout.hu', label: 'kb-layout-hu' },
    { value: 'kb-layout.is', label: 'kb-layout-is' },
    { value: 'kb-layout.ga', label: 'kb-layout-ga' },
    { value: 'kb-layout.it', label: 'kb-layout-it' },
    // { value: 'kb-layout.it-142', label: 'kb-layout-it-142' }, //no CDM value
    { value: 'kb-layout.ja-kana', label: 'kb-layout-ja-kana' },
    { value: 'kb-layout.ja-romaji', label: 'kb-layout-ja-romaji' },
    { value: 'kb-layout.ko', label: 'kb-layout-ko' },
    { value: 'kb-layout.lv', label: 'kb-layout-lv' },
    { value: 'kb-layout.lt', label: 'kb-layout-lt' },
    { value: 'kb-layout.no', label: 'kb-layout-no' },
    { value: 'kb-layout.pl', label: 'kb-layout-pl' },
    { value: 'kb-layout.pt-pt', label: 'kb-layout-pt-pt' },
    { value: 'kb-layout.pt-br', label: 'kb-layout-pt-br' },
    // { value: 'kb-layout.pt-br-2', label: 'kb-layout-pt-br-2' }, //no CDM value
    { value: 'kb-layout.ro', label: 'kb-layout-ro' },
    { value: 'kb-layout.ru', label: 'kb-layout-ru' },
    { value: 'kb-layout.sr-cyrl', label: 'kb-layout-sr-cyrl' },
    { value: 'kb-layout.sr-latn', label: 'kb-layout-sr-latn' },
    { value: 'kb-layout.sk', label: 'kb-layout-sk' },
    { value: 'kb-layout.sk-qwerty', label: 'kb-layout-sk-qwerty' },
    { value: 'kb-layout.sl', label: 'kb-layout-sl' },
    { value: 'kb-layout.es-es', label: 'kb-layout-es-es' },
    { value: 'kb-layout.es-mx', label: 'kb-layout-es-mx' },
    // { value: 'kb-layout.es-var', label: 'kb-layout-es-var' }, //no CDM value
    { value: 'kb-layout.sv', label: 'kb-layout-sv' },
    { value: 'kb-layout.th', label: 'kb-layout-th' },
    { value: 'kb-layout.tr-f', label: 'kb-layout-tr-f' },
    { value: 'kb-layout.tr-q', label: 'kb-layout-tr-q' },
    { value: 'kb-layout.uk', label: 'kb-layout-uk' }
  ]
}
