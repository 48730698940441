import React, { useContext, useState, useRef, useMemo, useEffect } from 'react'
import { Button, ButtonGroup, Table, ColumnIndexTypes } from '@veneer/core'
import configContext from 'context/config/configContext'
import { FlexColumn, FlexRow, TextBlack } from 'styles/styles'
import '../index.scss'
import 'styles/global.scss'
import HelpButton from '../../../HelpButton'
import { MOC } from 'common/mocParser/MocUtils'
import {
  calculateSizeFromBase64,
  getRowSelectAllState,
  generateBase64Value
} from 'common/utilities'
import WarningMessage from '../WarningMessage'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'

export enum FilelistUploadEnum {
  ID = 'id',
  NAME = 'name',
  SIZE = 'size',
  RECORD = 'record',
  ROW_CONFIG = 'rowConfig',
  SELECTED = 'selected'
}

const AppDeploymentFilelist = (props) => {
  const FILE_SIZE_LIMIT = 40 // KB
  const { tt } = useContext(configContext)
  const {
    localizationPath,
    configState,
    updateStateValue,
    fId,
    sId,
    set,
    preview,
    disabled,
    error,
    helperText
  } = props

  const fileInputRef = useRef(null)
  const [tableData, setTableData] = useState([])
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)
  const [selectedCount, setSelectedCount] = useState(0)
  const [maxFileSizeError, setMaxFileSizeError] = useState(false)
  const [duplicateFileError, setDuplicateFileError] = useState(false)

  useEffect(() => {
    if (configState) {
      const configValuesArray = configState?.[fId]?.[sId]?.[MOC.VALUES]
      setTableData(
        configValuesArray
          ? configValuesArray.map((x, i) => tableItem(x, i))
          : []
      )
    }
  }, [configState, fId, sId])

  useEffect(() => {
    const selected = tableData.filter(
      (row) => row[FilelistUploadEnum.ROW_CONFIG][FilelistUploadEnum.SELECTED]
    ).length
    setSelectedCount(selected)
    setRowSelectAllState(getRowSelectAllState(selected, tableData.length))
  }, [tableData])

  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: FilelistUploadEnum.ID, label: FilelistUploadEnum.ID, index },
      {
        id: FilelistUploadEnum.NAME,
        label: getLocalized('name')
      },
      {
        id: FilelistUploadEnum.SIZE,
        label: getLocalized('size')
      }
    ]
  }, [])

  const handleAddFile = () => fileInputRef.current.click()

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files
    if (!selectedFiles || selectedFiles.length === 0) return

    const uploadedFileNames = new Set(
      tableData.map((row) => row[FilelistUploadEnum.NAME])
    )
    setMaxFileSizeError(false)
    setDuplicateFileError(false)
    const loadAndRecordPromises = []

    for (const selectedFile of selectedFiles) {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) =>
          resolve({ [selectedFile.name]: generateBase64Value(event) })
        reader.onerror = (event) => reject(event)
        reader.readAsDataURL(selectedFile)
      })
      loadAndRecordPromises.push(promise)
    }

    const getFileSize = (record) =>
      calculateSizeFromBase64(Object.values(record)[0])

    Promise.all(loadAndRecordPromises).then((records) => {
      const tableDataArray = []
      let updatedFileSizeCount = tableData.reduce((accumulator, row) => {
        return (
          accumulator +
          getFileSize(
            row[FilelistUploadEnum.ROW_CONFIG][FilelistUploadEnum.RECORD]
          )
        )
      }, 0)

      for (let index = 0; index < records.length; index++) {
        const fileSize = getFileSize(records[index])
        if (updatedFileSizeCount + fileSize > FILE_SIZE_LIMIT << 10) {
          setMaxFileSizeError(true)
          continue
        }
        if (uploadedFileNames.has(Object.keys(records[index])[0])) {
          setDuplicateFileError(true)
          continue
        }
        updatedFileSizeCount += fileSize
        tableDataArray.push(tableItem(records[index], tableData.length + index))
        const configValuesArray = configState?.[fId]?.[sId][MOC.VALUES]
        const valueToSet = {
          [fId]: {
            [sId]: { [MOC.VALUES]: [...configValuesArray, records[index]] }
          }
        }
        uploadedFileNames.add(Object.keys(records[index])[0])
        updateStateValue(valueToSet)
      }
      setTableData([...tableData, ...tableDataArray])
    })
    fileInputRef.current.value = ''
  }

  const handleRemoveFile = () => {
    const unselectedRows = tableData.filter(
      (row) => !row[FilelistUploadEnum.ROW_CONFIG][FilelistUploadEnum.SELECTED]
    )
    const updatedRows = unselectedRows.map(
      (row) => row[FilelistUploadEnum.ROW_CONFIG][FilelistUploadEnum.RECORD]
    )
    const valueToSet = { [fId]: { [sId]: { [MOC.VALUES]: updatedRows } } }
    setTableData(unselectedRows)
    updateStateValue(valueToSet)
    setMaxFileSizeError(false)
  }

  const tableItem = (record, index) => {
    const name = Object.keys(record)[0]
    const selectedRowFlag = tableData.some(
      (item) =>
        item[FilelistUploadEnum.NAME] === name &&
        item[FilelistUploadEnum.ROW_CONFIG][FilelistUploadEnum.SELECTED]
    )
    return {
      [FilelistUploadEnum.ID]: index,
      [FilelistUploadEnum.NAME]: name,
      [FilelistUploadEnum.SIZE]: getLocalized('file-size', {
        fileSize: (
          calculateSizeFromBase64(Object.values(record)[0]) / 1024
        ).toFixed(2)
      }),
      [FilelistUploadEnum.ROW_CONFIG]: {
        [FilelistUploadEnum.RECORD]: record,
        [FilelistUploadEnum.SELECTED]: selectedRowFlag
      }
    }
  }

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const selectedRow = tableData.find(({ id }) => id === rowId)
    if (selectedRow) {
      selectedRow[FilelistUploadEnum.ROW_CONFIG][FilelistUploadEnum.SELECTED] =
        checked
      setTableData([...tableData])
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    setTableData(
      tableData.map((row) => {
        row[FilelistUploadEnum.ROW_CONFIG][FilelistUploadEnum.SELECTED] =
          checked
        return row
      })
    )
  }

  return (
    <>
      <FlexColumn>
        <FlexRow className={'alignCenter marginBottom8'}>
          <TextBlack disabled={disabled}>{set[MOC.LABEL]}</TextBlack>
          {!preview && (
            <FlexRow className={'paddingLeft4'}>
              <HelpButton
                enabled
                description={getLocalized('certificate-tooltip', {
                  fileSize: `${FILE_SIZE_LIMIT}`
                })}
              />
            </FlexRow>
          )}
        </FlexRow>
        {!preview && (
          <FlexRow className={'alignCenter marginBottom12'}>
            <ButtonGroup>
              <input
                type={'file'}
                ref={fileInputRef}
                onChange={handleFileChange}
                className={'displayNone'}
                accept={Object.keys(set[MOC.OPTIONS][0])[0]}
                multiple
              />
              <Button
                onClick={handleAddFile}
                disabled={disabled}
                appearance={'secondary'}
                id={'addFile'}
              >
                {getLocalized('common.add')}
              </Button>
              <Button
                onClick={handleRemoveFile}
                disabled={disabled || selectedCount < 1}
                appearance={'secondary'}
                id={'removeFile'}
              >
                {getLocalized('common.remove')}
              </Button>
            </ButtonGroup>
            <TextBlack className={'marginLeft12'} disabled={disabled}>
              {getLocalized('common.n-files-added', {
                count: tableData.length
              })}
            </TextBlack>
          </FlexRow>
        )}
        <Table
          columns={tableColumns}
          data={tableData}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          rowSelectAllState={rowSelectAllState}
          className={'widthColAuto'}
          rowSelector={!preview && !disabled ? 'multiSelection' : undefined}
          preferences={{
            width: [
              { columnId: FilelistUploadEnum.NAME, width: 360 },
              { columnId: FilelistUploadEnum.SIZE, width: 120 }
            ]
          }}
          data-testid={`id-app-deployment-${fId}-${sId}-table`}
        />
        {maxFileSizeError && (
          <WarningMessage
            message={getLocalized('max-file-size-error', {
              fileSize: `${FILE_SIZE_LIMIT}`
            })}
          />
        )}
        {duplicateFileError && (
          <WarningMessage message={getLocalized('duplicate-file-error')} />
        )}
        {error && (
          <WarningMessage type={MessageTypesEnum.ERROR} message={helperText} />
        )}
      </FlexColumn>
    </>
  )
}

export default AppDeploymentFilelist
