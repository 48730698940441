export const POLICY_DELETE_SCOPE = 'ws-hp.com/fleet/policy.DELETE'
export const POLICY_WRITE_SCOPE = 'ws-hp.com/fleet/policy.WRITE'
export const POLICY_CREATE_SCOPE = 'ws-hp.com/fleet/policy.CREATE'
export const POLICY_READ_SCOPE = 'ws-hp.com/fleet/policytemplate.READ'

export const WRITE_SCOPES = [{ scope: POLICY_WRITE_SCOPE }]
export const DELETE_SCOPES = [{ scope: POLICY_DELETE_SCOPE }]
export const READ_SCOPES = [{ scope: POLICY_READ_SCOPE }]
export const CREATE_SCOPES = [{ scope: POLICY_CREATE_SCOPE }]

export const allSolutionId = 'fleetmgt/all'

export const RELOAD_EVENT = 'ecp-banner-reload-call'

export enum ToastIdEnum {
  GET_ALL = 'id-toast-get-all',
  GET_POLICY = 'id-toast-get-policy',
  GET_TEMPLATES = 'id-toast-get-templates',
  CLONE_POLICY = 'id-toast-clone',
  IMPORT_POLICY = 'id-toast-import',
  REMOVE_POLICY = 'id-toast-remove',
  SAVE_POLICY = 'id-toast-save',
  CREATE_DEVICE_POLICY = 'id-toast-create-device',
  SAVE_DEVICE_POLICY = 'id-toast-save-device',
  REMOVE_DEVICE_POLICY = 'id-toast-remove-device',
  GET_DEVICE = 'id-toast-get-device',
  RUN_NOW_DONE = 'id-toast-run-now-done',
  RUN_NOW_ERROR = 'id-toast-run-now-error'
}

export enum PolicyAttributeEntitlementEnum {
  ESSENTIAL = 'ws-hp.com/smcloud',
  ADVANCED = 'ws-hp.com/smcloud-advanced'
}

export enum PolicyAttributeComplianceEnum {
  COMPLIANT = 'compliant',
  NONCOMPLIANT = 'noncompliant',
  OVERRIDDEN = 'overridden',
  UNKNOWN = 'unknown'
}
