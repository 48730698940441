import React, { useContext, memo } from 'react'
import uuid from 'react-uuid'
import {
  Button,
  IconTrash,
  IconXCircle,
  IconInfo,
  IconWarningAlt,
  IconMinusCircle,
  IconCheckmarkCircle,
  Tooltip
} from '@veneer/core'
import { FlexRow } from 'styles/styles'
import configContext from 'context/config/configContext'
import { PolicyAttributeComplianceEnum } from 'components/policies/constants'
import 'styles/global.scss'
import './index.scss'

const PolicyHeaderRight = (props) => {
  const { t } = useContext(configContext)
  const { description, status, errors, warnings, onRemove } = props

  const renderError = (showToolTip: boolean) => {
    return showToolTip ? (
      <Tooltip
        content={t('policy.details.card.error')}
        id={'waring-tooltip'}
        placement={'top-end'}
        portal={true}
        arrow
      >
        <IconMinusCircle customStyle={{ zIndex: 1 }} filled color={'red7'} />
      </Tooltip>
    ) : (
      <IconMinusCircle filled color={'red7'} />
    )
  }

  const renderWarning = (showToolTip: boolean) => {
    return showToolTip ? (
      <Tooltip
        content={t('policy.details.card.warning')}
        id={'waring-tooltip'}
        placement={'top-end'}
        portal={true}
        arrow
      >
        <IconWarningAlt
          customStyle={{ zIndex: 1 }}
          filled
          color={'darkOrange5'}
        />
      </Tooltip>
    ) : (
      <IconWarningAlt filled color={'darkOrange5'} />
    )
  }

  const renderStatus = () => {
    switch (status) {
      case PolicyAttributeComplianceEnum.COMPLIANT:
        return <IconCheckmarkCircle filled color={'green7'} />
      case PolicyAttributeComplianceEnum.NONCOMPLIANT:
        return <IconXCircle filled color={'red7'} />
      case PolicyAttributeComplianceEnum.OVERRIDDEN:
        return <IconWarningAlt filled color={'darkOrange5'} />
      case PolicyAttributeComplianceEnum.UNKNOWN:
      default:
        return <IconInfo filled color={'gray7'} />
    }
  }

  return (
    <>
      {!description ? (
        <FlexRow className={'alignCenter'}>
          <FlexRow className={'marginRight8 alignCenter'}>
            {renderError(false)}
            <div className={'rightCountLabel marginLeft4'}>{errors}</div>
          </FlexRow>
          <FlexRow className={'alignCenter'}>
            {renderWarning(false)}
            <div className={'rightCountLabel marginLeft4'}>{warnings}</div>
          </FlexRow>
        </FlexRow>
      ) : status ? (
        <FlexRow className={'rightBtnMargin'}>{renderStatus()}</FlexRow>
      ) : (
        <FlexRow className={'alignCenter'}>
          {errors > 0 && (
            <FlexRow className={'rightBtnMargin'}>{renderError(true)}</FlexRow>
          )}
          {warnings > 0 && (
            <FlexRow className={'rightBtnMargin'}>
              {renderWarning(true)}
            </FlexRow>
          )}
          {onRemove && (
            <Button
              id={'policySettingRemoval'}
              key={uuid()}
              appearance={'ghost'}
              onClick={onRemove}
              small={true}
              leadingIcon={<IconTrash />}
            />
          )}
        </FlexRow>
      )}
    </>
  )
}

export default memo(PolicyHeaderRight)
