import React, { useContext } from 'react'
import uuid from 'react-uuid'

import { Button, ButtonGroup, IconWarning, Modal } from '@veneer/core'
import configContext from 'context/config/configContext'
import { FlexRow } from 'styles/styles'
import 'styles/global.scss'

const Save = (props) => {
  const { t } = useContext(configContext)

  const getLocalized = (key: string): string => {
    return t(`policy.details.footer.warningModal.${key}`)
  }

  return (
    props.show && (
      <Modal
        onClose={props.onCancel}
        closeOnBlur={false}
        show={props.show}
        className={'small-policy-modal'}
        data-testid={'id-save-modal'}
        footer={
          <ButtonGroup>
            <Button id={'idSave'} onClick={props.onSave}>
              {t('common.save')}
            </Button>
            <Button
              id={'idCancelSaveWarning'}
              appearance={'secondary'}
              onClick={props.onCancel}
            >
              {t('common.cancel')}
            </Button>
          </ButtonGroup>
        }
      >
        <FlexRow className={'marginBottom20 alignCenter'}>
          <IconWarning filled color={'hpBlue7'} size={36} key={uuid()} />
          <h4 className={'marginLeft8'} data-testid={'id-save-modal-title'}>
            {getLocalized('title')}
          </h4>
        </FlexRow>
        <p
          className={'paddingBottom16 lineHeight20'}
          data-testid={'id-save-modal-text-1'}
        >
          <span className={'paddingRight16'}>{getLocalized('policyName')}</span>
          <span data-testid={'id-save-modal-name'} className={'wrapAnywhere'}>
            {props.policyName}
          </span>
        </p>
        <p data-testid={'id-save-modal-text-2'}>
          {getLocalized('description')}
        </p>
      </Modal>
    )
  )
}

export default Save
